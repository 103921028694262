import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

const TeamPhotos = () => {
  const backend = import.meta.env.VITE_APP_BACKEND;
  return (
    <Box component="section" bgcolor="background">
      <Typography variant="h4" component="h2" sx={{ mb: 4 }}>
        Team Photos
      </Typography>
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAM76ERS.jpg"}
        alt="76ers"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMBALLERS.jpg"}
        alt="ballers"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMBUCKS.jpg"}
        alt="bucks"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMCHEERFUL.jpg"}
        alt="cheerful"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMCOBRAS.jpg"}
        alt="cobras"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMDIAMONDBACKS.jpg"}
        alt="diamondbacks"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMEAGLES.jpg"}
        alt="eagles"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMFALCONS.jpg"}
        alt="falcons"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMGLADIATORS.jpg"}
        alt="gladiators"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMGOLDEN.jpg"}
        alt="golden"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMGOLDEN2.jpg"}
        alt="golden2"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMHURRICANES.jpg"}
        alt="hurricanes"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMJAGUARS.jpg"}
        alt="jaguars"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMKRAKKENS.jpg"}
        alt="Krakkens"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMKRAKKENS2.jpg"}
        alt="krakkens"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMPACERS.jpg"}
        alt="pacers"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMPANTHERS.jpg"}
        alt="panthers"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMRAIDERS.jpg"}
        alt="raiders"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMRANGERS.jpg"}
        alt="rangers"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMSPARK.jpg"}
        alt="sparklers"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMSTORM.jpg"}
        alt="storm"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMTHUNDER.jpg"}
        alt="thunder"
      />
      <Box
        component="img"
        crossOrigin="anonymous"
        sx={{ maxWidth: "1200px" }}
        src={backend + "/team_images/TEAMWILDCATS.jpg"}
        alt="wildcats"
      />
    </Box>
  );
};

export default TeamPhotos;

import axios from "axios";
import authHeader from "./auth-header";
import Backend from "../utilities/Backend";

const API_URL = `${Backend}/visitor`;

const VisitorService = new (class UserService {
  visitors() {
    return axios.get(API_URL);
  }
})();

export default VisitorService;

import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  SearchInput,
} from "react-admin";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useMediaQuery, Theme } from "@mui/material";

import MobileGrid from "./MobileGrid";

const localizer = dayjsLocalizer(dayjs);

const GameList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm"),
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  return (
    <Box component="section" bgcolor="background">
      <List perPage={25}>
        {isXsmall ? (
          <MobileGrid />
        ) : (
          <Datagrid
            optimized
            rowClick="edit"
            sx={{
              "& .column-groups": {
                md: { display: "none" },
                lg: { display: "table-cell" },
              },
            }}
          >
            <TextField source="day" />
            <FunctionField
              source="time"
              render={(record: any) => {
                const date = new Date(record.time);
                const dateString = date.toLocaleString("en-US", {
                  timeZone: "America/Chicago",
                });
                const time = dateString.split(", ")[1];
                const hour = time.split(":")[0];
                const minute = time.split(":")[1];
                const tod = time.split(" ")[1];
                return hour + ":" + minute + " " + tod;
              }}
            />
          </Datagrid>
        )}
      </List>
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: "relative",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" component="h2" sx={{ mb: 4 }}>
          Season Schedule
        </Typography>

        <Calendar
          localizer={localizer}
          // events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
        />
      </Container>
    </Box>
  );
};

export default GameList;

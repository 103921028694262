// in src/comments.js
import * as React from "react";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import {
  DateField,
  EditButton,
  TextField,
  RecordContextProvider,
  useListContext,
} from "react-admin";

import AvatarField from "./AvatarField";
import { User } from "../../types";

const MobileGrid = () => {
  const { data, isLoading } = useListContext<User>();

  if (isLoading || data.length === 0) {
    return null;
  }
  return (
    <Box margin="0.5em">
      {data.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
          <Card sx={{ margin: "0.5rem 0" }}>
            <CardHeader
              title={`${record.firstName} ${record.lastName}`}
              subheader={
                <>
                  {/* {translate(
                                        'resources.customers.fields.last_seen_gte'
                                    )} */}
                  &nbsp;
                  <DateField source="last_seen" />
                </>
              }
              avatar={<AvatarField size="45" />}
              action={<EditButton />}
            />
            <CardContent sx={{ pt: 0 }}>
              <Typography variant="body2">
                Email :&nbsp;
                <TextField source="email" />
              </Typography>
              <Typography variant="body2">
                Phone :&nbsp;
                <TextField source="phone" />
              </Typography>
              <Typography variant="body2">
                Registered Players :&nbsp;
                {record.registeredPlayers.length > 0
                  ? record.registeredPlayers.map((player) => {
                      return `${player.firstName}, `;
                    })
                  : "None"}
              </Typography>
              <Typography variant="body2">
                Last seen :&nbsp;
                <DateField source="lastSeen" />
              </Typography>
            </CardContent>
            {/* {record.fullyPaid && record.groups.length > 0 && (
              <CardContent sx={{ pt: 0 }}>
                <SegmentsField />
              </CardContent>
            )} */}
          </Card>
        </RecordContextProvider>
      ))}
    </Box>
  );
};

export default MobileGrid;

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Basketball from "../images/Game_Schedule.png";
import Cheer from "../images/Cheer_Schedule.png";

const Schedule = () => {
  return (
    <Box component="section" bgcolor="background">
      {/* <Container
                sx={{
                    mt: 10,
                    mb: 15,
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            > */}
      <Typography variant="h4" component="h2" sx={{ mb: 4 }}>
        Basketball Schedule
      </Typography>
      <Box component="img" src={Basketball} alt="basketball schedule" />

      <Typography variant="h4" component="h2" sx={{ mb: 4, mt: 7 }}>
        Cheer Schedule
      </Typography>
      <Box component="img" src={Cheer} alt="cheer schedule" />
      {/* </Container> */}
    </Box>
  );
};

export default Schedule;

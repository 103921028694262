import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import Typography from "./modules/components/Typography";
import EventBus from "../utilities/EventBus";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
} from "@mui/material";
import Court from "../images/court.jpg";
import Logo from "../images/uplift_logo.svg?react";
import {
  Form,
  required,
  TextInput,
  useTranslate,
  useLogin,
  useNotify,
} from "react-admin";

import Box from "@mui/material/Box";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Login = () => {
  const forgotPasswordUrl = "/forgotpassword";
  const signupUrl = "/signup";
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const translate = useTranslate();

  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    if (auth.password) setPw(auth.password);
    if (auth.email) setEmail(auth.email);
    if (!auth.email && email !== "") auth.email = email;
    if (!auth.password && pw !== "") auth.password = pw;
    login(auth, location.state ? (location.state as any).nextPathname : "/")
      .then(() => {
        EventBus.dispatch("refresh");
      })
      .catch((error: Error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
              ? "ra.auth.sign_in_error"
              : error.message,
          {
            type: "error",
            messageArgs: {
              _:
                typeof error === "string"
                  ? error
                  : error && error.message
                    ? "Invalid Email/Password"
                    : undefined,
            },
          },
        );
      });
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "flex-start",
          // background:
          //     'url(https://source.unsplash.com/featured/1600x900)',
          // backgroundRepeat: 'no-repeat',
          // backgroundSize: 'cover',
        }}
      >
        <Card sx={{ minWidth: 300, marginTop: "6em", zIndex: 1 }}>
          <Box
            sx={{
              margin: "1em",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link href="/">
              <SvgIcon
                component={Logo}
                inheritViewBox
                sx={{ width: "80px", height: "80px" }}
              />
            </Link>
          </Box>
          <Box
            sx={{
              marginTop: "1em",
              display: "flex",
              justifyContent: "center",
              color: (theme) => theme.palette.grey[500],
            }}
          ></Box>
          <Box sx={{ padding: "0 1em 1em 1em" }}>
            <Box sx={{ marginTop: "1em" }}>
              <TextInput
                autoFocus
                source="email"
                label="Email"
                disabled={loading}
                validate={required()}
                fullWidth
              />
            </Box>
            <Box sx={{ marginTop: "1em" }}>
              <TextInput
                source="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                disabled={loading}
                validate={required()}
                fullWidth
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <CardActions sx={{ padding: "0 1em 1em 1em" }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              {translate("ra.auth.sign_in")}
            </Button>
          </CardActions>
          <Typography align="center">
            <Link href={forgotPasswordUrl} variant="body2">
              Forgot password?
            </Link>
          </Typography>
          <Typography align="center">
            <Link href={signupUrl} variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Typography>
        </Card>
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zIndex: 0,
          backgroundColor: "#7fc7d9", // Average color of the background image.
          backgroundPosition: "center",
          backgroundImage: `url(${Court})`,
        }}
      ></Box>
    </Form>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default Login;

interface FormValues {
  email?: string;
  password?: string;
}

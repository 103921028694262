import * as React from "react";
import { TimeInput, Edit, SelectInput, SimpleForm } from "react-admin";
import { Grid, Box, Typography } from "@mui/material";

import { validateForm } from "./GameCreate";

const GameEdit = () => {
  return (
    <Edit>
      <SimpleForm sx={{ maxWidth: 500 }} validate={validateForm}>
        <Typography variant="h6" gutterBottom>
          Games
        </Typography>
        <SelectInput
          source="day"
          isRequired
          choices={[
            { id: "Mon", name: "Monday" },
            { id: "Tues", name: "Tuesday" },
            { id: "Wed", name: "Wednesday" },
            { id: "Thurs", name: "Thursday" },
            { id: "Fri", name: "Friday" },
            { id: "Sat", name: "Saturday" },
            { id: "Sun", name: "Sunday" },
          ]}
        />
        <TimeInput source="time" isRequired />
      </SimpleForm>
    </Edit>
  );
};

export default GameEdit;

import * as React from "react";
import {
  Create,
  SelectInput,
  SimpleForm,
  NumberInput,
  BooleanInput,
  TimeInput,
} from "react-admin";
import { Box, Typography } from "@mui/material";

export const validateForm = (
  values: Record<string, any>,
): Record<string, any> => {
  const errors = {} as any;
  if (!values.day) {
    errors.day = "ra.validation.required";
  }
  if (!values.time) {
    errors.time = "ra.validation.required";
  }
  return errors;
};

const GameCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm
        sx={{ maxWidth: 500 }}
        // Here for the GQL provider

        validate={validateForm}
      >
        <Typography variant="h6" gutterBottom>
          Games
        </Typography>
        <SelectInput
          source="day"
          isRequired
          choices={[
            { id: "Mon", name: "Monday" },
            { id: "Tues", name: "Tuesday" },
            { id: "Wed", name: "Wednesday" },
            { id: "Thurs", name: "Thursday" },
            { id: "Fri", name: "Friday" },
            { id: "Sat", name: "Saturday" },
            { id: "Sun", name: "Sunday" },
          ]}
          defaultValue={"Sat"}
        />
        <TimeInput source="time" isRequired />
      </SimpleForm>
    </Create>
  );
};

export default GameCreate;

import {
  Datagrid,
  DateField,
  DateInput,
  List,
  TextField,
  SearchInput,
  BulkUpdateButton,
} from "react-admin";
import { useMediaQuery, Theme } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import MobileGrid from "./MobileGrid";

const CommunicationFilters = [
  <SearchInput source="q" alwaysOn />,
  <DateInput source="last_seen_gte" />,
];

// const PostBulkActionButtons = () => (
//     <>
//         <BulkUpdateFormButton label="Send Message" icon={<MailIcon/>} />
//     </>
// );

const CommunicationList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm"),
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  return (
    <List
      filters={isSmall ? CommunicationFilters : undefined}
      sort={{ field: "end", order: "DESC" }}
      perPage={25}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid
          // bulkActionButtons={<PostBulkActionButtons />}
          optimized
          rowClick="show"
          sx={{
            "& .column-groups": {
              md: { display: "none" },
              lg: { display: "table-cell" },
            },
          }}
        >
          <DateField source="date" options={{ timeZone: "America/Chicago" }} />
          <TextField source="to" />
          <TextField source="from" />
          <TextField source="subject" />
          <TextField source="message" />
        </Datagrid>
      )}
    </List>
  );
};

export default CommunicationList;

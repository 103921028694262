import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PracticeList from "./PracticeList";
import PracticeCreate from "./PracticeCreate";
import PracticeEdit from "./PracticeEdit";

const resource = {
  list: PracticeList,
  create: PracticeCreate,
  edit: PracticeEdit,
  icon: CalendarMonthIcon,
  recordRepresentation: (record: any) => `${record.title}`,
};

export default resource;

import { AuthProvider, HttpError, useStore } from "react-admin";
import axios from "axios";
import EventBus from "../utilities/EventBus";
import Backend from "../utilities/Backend";

const API_URL = `${Backend}/auth/`;

export const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    const response = await axios.post(API_URL + "signin", {
      email,
      password,
    });
    if (response.data.accessToken) {
      localStorage.setItem("user", JSON.stringify(response.data));
      return Promise.resolve();
    }
    return await Promise.reject(
      new HttpError("Unauthorized", 401, {
        message: "Invalid email or password",
      }),
    );
  },
  logout: () => {
    localStorage.removeItem("user");
    EventBus.dispatch("refresh");
    return Promise.resolve("/");
  },
  checkError: () => Promise.resolve(),
  checkAuth: (params: any) => {
    if (params?.route === "dashboard") return Promise.resolve();
    if (localStorage.getItem("user")) return Promise.resolve();
    else return Promise.reject();
  },
  getPermissions: () => {
    return Promise.resolve(undefined);
  },
  getAuthenticated: () => {
    const persistedUser = localStorage.getItem("user");
    return persistedUser ? JSON.parse(persistedUser) : false;
  },
  isAdmin: () => {
    const persistedUser = localStorage.getItem("user");
    const user = persistedUser ? JSON.parse(persistedUser) : null;
    const isAdmin = user ? user.roles.includes("ROLE_ADMIN") : false;
    return isAdmin;
  },
  isVolunteer: () => {
    const persistedUser = localStorage.getItem("user");
    const user = persistedUser ? JSON.parse(persistedUser) : null;
    const isAdmin = user ? user.roles.includes("ROLE_VOLUNTEER") : false;
    return isAdmin;
  },
  isReferee: () => {
    const persistedUser = localStorage.getItem("user");
    const user = persistedUser ? JSON.parse(persistedUser) : null;
    const isAdmin = user ? user.roles.includes("ROLE_REFEREE") : false;
    return isAdmin;
  },
  isCoach: () => {
    const persistedUser = localStorage.getItem("user");
    const user = persistedUser ? JSON.parse(persistedUser) : null;
    const isCoach = user ? user.roles.includes("ROLE_COACH") : false;
    return isCoach;
  },
  isMember: () => {
    const persistedUser = localStorage.getItem("user");
    const user = persistedUser ? JSON.parse(persistedUser) : null;
    const isMember = user ? user.isMember : false;
    return isMember;
  },
  getIdentity: () => {
    const persistedUser = localStorage.getItem("user");
    const user = persistedUser ? JSON.parse(persistedUser) : null;

    return Promise.resolve(user);
  },
  forgotPassword: async (email: string) => {
    return await axios
      .post(API_URL + "forgot-password", { email })
      .then((response) => {
        return Promise.resolve(response.data);
      });
  },

  confirmCode: async (email: string, code: string) => {
    return await axios
      .post(API_URL + "code-confirmation", { email, code })
      .then((response) => {
        return Promise.resolve(response.data);
      });
  },

  changePassword: async (
    email: string,
    newPassword: string,
    oldPassword?: string,
    code?: string,
  ) => {
    return await axios
      .post(API_URL + "change-password", {
        email,
        newPassword,
        oldPassword,
        code,
      })
      .then((response) => {
        return Promise.resolve(response.data);
      });
  },
  signUp: async (
    firstName: string,
    lastName: string,
    address: string,
    address2: string,
    city: string,
    state: string,
    zip: string,
    phone: string,
    email: string,
    password: string,
    isMember: boolean,
    advertising: string[],
    textConsent: boolean,
  ) => {
    return await axios
      .post(API_URL + "signup", {
        firstName,
        lastName,
        address,
        address2,
        city,
        state,
        zip,
        phone,
        email,
        password,
        isMember,
        advertising,
        textConsent,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return Promise.resolve(response.data);
      });
  },
};

export default authProvider;

/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui icons
import React, { useState, useEffect, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AuthService from "../services/auth.service";
import { Formik, Form, Field, ErrorMessage } from "formik";
import UserService from "../services/user.service";
import VolunteerService from "../services/volunteer.service";
import SubscriptionService from "../services/subscription.service";
import EventBus from "../utilities/EventBus";

import {
  Box,
  Typography,
  Card,
  Grid,
  Tabs,
  AppBar,
  Tab,
  Icon,
  Divider,
  Avatar,
} from "@mui/material";

// Material Dashboard 2 React example components
import AppFooter from "./modules/views/AppFooter";
import PlayerInfoCard from "./modules/components/PlayerInfoCard";
// import ProfilesList from "examples/Lists/ProfilesList";

// Overview page components
import Header from "./modules/components/ProfileHeader";
import PlatformSettings from "./modules/components/ProfileSettings";

var initialValues = {
  id: "",
  registeredPlayers: [],
};

function PlayerInfo() {
  const [redirect, setRedirect] = useState("");
  const [playerInfo, setPlayerInfo] = useState(initialValues);
  const [fetchedUserData, setFetchedUserData] = useState(false);
  const location = useLocation().pathname;

  const fetchData = async () => {
    await UserService.getPlayers().then(
      (response) => {
        const registeredPlayers = response.data.registeredPlayers;
        initialValues.registeredPlayers =
          registeredPlayers.length === 0 ? [] : registeredPlayers;
        setPlayerInfo(initialValues);
        setFetchedUserData(true);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
          setRedirect("/");
        }
      },
    );
  };

  useEffect(() => {
    if (location === "/playerinfo") {
      const currentUser = AuthService.getAuthenticated();

      if (!currentUser) setRedirect("/");
      if (!fetchedUserData) fetchData();
    }
  }, [fetchedUserData, location]);

  if (redirect !== "") {
    return <Navigate to={redirect} />;
  } else if (fetchedUserData) {
    return (
      <>
        <Box mb={2} />
        <Header name={"Registered Players"}>
          <Box mt={5} mb={3}>
            <Grid container spacing={1}>
              {playerInfo.registeredPlayers.length === 0 ? (
                <Typography>
                  You don't currently have any registered players
                </Typography>
              ) : (
                playerInfo.registeredPlayers.map(
                  (player: any, index: number) => (
                    <Grid key={index} item xs={12} md={4}>
                      {/* <Divider orientation="vertical" sx={{ mx: 0 }} /> */}
                      <PlayerInfoCard
                        title={player.firstName}
                        data={player}
                        shadow={false}
                      />
                    </Grid>
                  ),
                )
              )}

              {/* TODO: Would be cool to have */}
              {/* <Grid item xs={12} xl={4}>
              <ProfilesList title="conversations" profiles={profilesListData} shadow={false} />
            </Grid> */}
            </Grid>
          </Box>
        </Header>
        <AppFooter />
      </>
    );
  }
}

export default PlayerInfo;

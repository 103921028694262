import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import {
  BrowserRouter as Router,
  Navigate,
  useLocation,
} from "react-router-dom";
import PaymentService from "../services/payment.service";
import Box from "@mui/material/Box";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

export const CheckoutForm = () => {
  const [clientSecret, setClientSecret] = useState("");
  const location = useLocation().pathname;
  const { state } = useLocation();
  const playerIds = state.players;

  useEffect(() => {
    if (location === "/checkout") {
      // Create a Checkout Session as soon as the page loads
      PaymentService.createCheckoutSession(playerIds)
        .then((response) => response.data)
        .then((data) => setClientSecret(data.clientSecret));
    }
  }, []);

  return (
    <div id="checkout">
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");
  const location = useLocation().pathname;

  useEffect(() => {
    if (location === "/return") {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get("session_id");

      PaymentService.sessionStatus(sessionId)
        .then((res) => res.data)
        .then((data) => {
          setStatus(data.status);
          setCustomerEmail(data.customer_email);
        });
    }
  }, []);

  if (status === "open") {
    return <Navigate to="/checkout" />;
  }

  if (status === "complete") {
    return (
      <Box>
        <section id="success">
          <p>
            Thanks for registering! A confirmation email will be sent to{" "}
            {customerEmail}. If you have any questions, please email{" "}
            <a href="mailto:uplift@tannehillvalley.org">
              uplift@tannehillvalley.org
            </a>
            .
          </p>
        </section>
      </Box>
    );
  }

  return null;
};

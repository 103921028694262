import * as React from "react";
import {
  BooleanField,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  ExportButton,
  List,
  TextField,
  NullableBooleanInput,
  NumberField,
  SearchInput,
  SelectColumnsButton,
  TopToolbar,
} from "react-admin";
import { useMediaQuery, Theme } from "@mui/material";

import UserLinkField from "./UserLinkField";
import RegisteredPlayersField from "./RegisteredPlayers";
import MobileGrid from "./MobileGrid";
import VisitorListAside from "./VisitorListAside";

const visitorFilters = [
  <SearchInput source="q" alwaysOn />,
  <DateInput source="last_seen_gte" />,
  <NullableBooleanInput source="has_ordered" />,
  <NullableBooleanInput source="has_newsletter" defaultValue />,
  // <SegmentInput source="groups" />,
];

const VisitorListActions = () => (
  <TopToolbar>
    <CreateButton />
    <SelectColumnsButton />
    <ExportButton />
  </TopToolbar>
);

const VisitorList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm"),
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  return (
    <List
      filters={isSmall ? visitorFilters : undefined}
      sort={{ field: "user", order: "ASC" }}
      perPage={25}
      aside={<VisitorListAside />}
      actions={<VisitorListActions />}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <DatagridConfigurable
          rowClick="edit"
          sx={{
            "& .column-groups": {
              md: { display: "none" },
              lg: { display: "table-cell" },
            },
          }}
          omit={["birthday"]}
        >
          <UserLinkField source="user" />
          <TextField source="email" />
          <TextField source="phone" />
          <RegisteredPlayersField source="Athletes" />
          <BooleanField source="fullyPaid" />
          {/* <NumberField
                        source="nb_commands"
                        label="commands"
                    />
                    <ColoredNumberField
                        source="total_spent"
                        options={{ style: 'currency', currency: 'USD' }}
                    />
                    <DateField source="latest_purchase" showTime />
                    <BooleanField source="has_newsletter" label="News." />
                    <SegmentsField source="groups" /> */}
        </DatagridConfigurable>
      )}
    </List>
  );
};

export default VisitorList;

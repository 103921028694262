import { LoadingIndicator, LocalesMenuButton } from "react-admin";
import { CoachToggle } from "./CoachToggle";
import { ThemeSwapper } from "../themes/ThemeSwapper";

export const AppBarToolbar = () => (
  <>
    {/* <LocalesMenuButton /> */}
    <CoachToggle />
    <ThemeSwapper />
    <LoadingIndicator />
  </>
);

import * as React from "react";
import { Link, FieldProps, useRecordContext } from "react-admin";

import PlayerFullNameField from "./PlayerFullNameField";
import { User } from "../../types";
import { Box } from "@mui/material";

const RegisteredPlayersField = (_: FieldProps<User>) => {
  const record = useRecordContext<User>();
  if (!record || record.registeredPlayers.length === 0) {
    return null;
  }
  return (
    <Box>
      {record.registeredPlayers.map((player: any, index) => {
        return (
          <Link to={`/players/${player.id}`} key={player.id}>
            <PlayerFullNameField playerCounter={index} />
          </Link>
        );
      })}
    </Box>
  );
};

RegisteredPlayersField.defaultProps = {
  source: "customer_id",
};

export default RegisteredPlayersField;

import axios from "axios";
import authHeader from "./auth-header";
import Backend from "../utilities/Backend";

const API_URL = `${Backend}/payment/`;
const PaymentService = new (class PaymentService {
  createCheckoutSession(players: string[]) {
    return axios.post(
      API_URL + "create-checkout-session",
      {
        players,
      },
      { headers: authHeader() },
    );
  }

  sessionStatus(sessionId: string | null) {
    return axios.get(API_URL + `session-status?session_id=${sessionId}`, {
      headers: authHeader(),
    });
  }
})();

export default PaymentService;

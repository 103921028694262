import * as React from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SvgIcon from "@mui/material/SvgIcon";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import SportsIcon from "@mui/icons-material/Sports";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import Background from "../../../images/appCurvyLines.png";
const item: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "background" }}
    >
      <Container sx={{ mt: 15, display: "flex", position: "relative" }}>
        <Box
          component="img"
          src={Background}
          alt="curvy lines"
          sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <SvgIcon
                component={SportsIcon}
                inheritViewBox
                sx={{ width: "60px", height: "60px" }}
              />

              <Typography variant="h6" sx={{ my: 5 }}>
                Enforce Teamwork
              </Typography>
              <Typography variant="h5">
                {"Kids learn to work together towards a common goal"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <SvgIcon
                component={SportsBasketballIcon}
                inheritViewBox
                sx={{ width: "60px", height: "60px" }}
              />

              <Typography variant="h6" sx={{ my: 5 }}>
                Learn Sportsmanship
              </Typography>
              <Typography variant="h5">
                {
                  "We work to teach kids to lift each other up and help each other out"
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <SvgIcon
                component={Diversity3Icon}
                inheritViewBox
                sx={{ width: "60px", height: "60px" }}
              />

              <Typography variant="h6" sx={{ my: 5 }}>
                Make friends
              </Typography>
              <Typography variant="h5">
                {"Meet similiarly aged kids from the community"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;

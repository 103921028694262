import * as React from "react";
import { Card, CardContent } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOnOutlined";
import MailIcon from "@mui/icons-material/MailOutline";
import LocalOfferIcon from "@mui/icons-material/LocalOfferOutlined";
import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  SavedQueriesList,
} from "react-admin";

import segments from "../segments/data";

const Aside = () => (
  <Card
    sx={{
      display: {
        xs: "none",
        md: "block",
      },
      order: -1,
      flex: "0 0 15em",
      mr: 2,
      mt: 6,
      alignSelf: "flex-start",
    }}
  >
    <CardContent sx={{ pt: 1 }}>
      <FilterLiveSearch hiddenLabel />

      <FilterList label="Is Fully Paid" icon={<MonetizationOnIcon />}>
        <FilterListItem
          label="true"
          value={{
            fullyPaid: true,
          }}
        />
        <FilterListItem
          label="false"
          value={{
            fullyPaid: false,
          }}
        />
      </FilterList>

      <FilterList label="Subscribed to newsletter" icon={<MailIcon />}>
        <FilterListItem label="true" value={{ has_newsletter: true }} />
        <FilterListItem label="false" value={{ has_newsletter: false }} />
      </FilterList>

      {/* <FilterList label="division" icon={<LocalOfferIcon />}>
        {segments.map((segment) => (
          <FilterListItem
            label={segment.name}
            key={segment.id}
            value={{ groups: segment.id }}
          />
        ))}
      </FilterList> */}
    </CardContent>
  </Card>
);

export default Aside;

import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Typography from "../components/Typography";
// import TextField from '../components/TextField';
// import Snackbar from '../components/Snackbar';
import Button from "../components/Button";
import Background from "../../../images/productCTAImageDots.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import subscriptionService from "../../../services/subscription.service";
import contactImage from "../../../images/contact.jpg";

function ProductCTA() {
  const [subscribed, setSubscribed] = React.useState(false);

  const validationSchema = () => {
    return Yup.object({
      email: Yup.string()
        .email("This is not a valid email.")
        .required("Email cannot be blank"),
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubscribed(true);
      const { email } = values;
      subscriptionService.subscribe(email);
    },
  });

  const handleClose = () => {
    setSubscribed(false);
  };

  return (
    <Container component="section" sx={{ mt: 10, display: "flex" }}>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              bgcolor: "warning.main",
              py: 8,
              px: 3,
            }}
          >
            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              noValidate
              sx={{ maxWidth: 400 }}
            >
              <Typography variant="h2" component="h2" gutterBottom>
                Stay Updated
              </Typography>
              <Typography variant="h5">
                Subscribe to receive the latest news about the league
              </Typography>
              <TextField
                name="email"
                placeholder="Your email"
                variant="outlined"
                sx={{ width: "100%", mt: 3, mb: 2 }}
                color="secondary"
                focused
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                sx={{ width: "100%" }}
              >
                Keep me updated
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { md: "block", xs: "none" }, position: "relative" }}
        >
          <Box
            sx={{
              position: "absolute",
              top: -67,
              left: -67,
              right: 0,
              bottom: 0,
              width: "100%",
              background: { Background },
            }}
          />
          <Box
            component="img"
            src={contactImage}
            alt="call to action"
            sx={{
              position: "absolute",
              top: -28,
              left: -28,
              right: 0,
              bottom: 0,
              width: "100%",
              maxWidth: 600,
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 360,
              left: 280,
              right: 0,
              bottom: 0,
              // width: '100%'
            }}
          >
            <a href="https://www.freepik.com/free-photo/laptop-with-blank-white-screen_10421171.htm#query=computer&position=49&from_view=keyword&track=sph&uuid=166fdde3-13ba-4b68-8dae-fae9b74b0f10">
              Image by wayhomestudio
            </a>{" "}
            on Freepik
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={subscribed}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Thanks for Subscibing!
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ProductCTA;

import { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import "react-big-calendar/lib/css/react-big-calendar.css";
import EventService from "../services/event.service";

const localizer = dayjsLocalizer(dayjs);

const SeasonCalendar = () => {
  const [events, setEvents] = useState([]);
  const [fetchedData, setFetchedData] = useState(false);

  const location = useLocation().pathname;

  const getEvents = async () => {
    await EventService.getEvents().then((response) => {
      const events = response.data.events;
      setEvents(events);
      setFetchedData(true);
    });
  };
  useEffect(() => {
    if (location === "/eventcalendar") {
      if (!fetchedData) getEvents();
    }
  }, [fetchedData]);
  return (
    <Box component="section" bgcolor="background">
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: "relative",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" component="h2" sx={{ mb: 4 }}>
          Season Schedule
        </Typography>

        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
        />
      </Container>
    </Box>
  );
};

export default SeasonCalendar;

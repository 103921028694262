import {
  Datagrid,
  DateField,
  DateInput,
  List,
  TextField,
  SearchInput,
} from "react-admin";
import { useMediaQuery, Theme } from "@mui/material";

import MobileGrid from "./MobileGrid";

const EventFilters = [
  <SearchInput source="q" alwaysOn />,
  <DateInput source="last_seen_gte" />,
];

const EventList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm"),
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  return (
    <List
      filters={isSmall ? EventFilters : undefined}
      sort={{ field: "end", order: "DESC" }}
      perPage={25}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            "& .column-groups": {
              md: { display: "none" },
              lg: { display: "table-cell" },
            },
          }}
        >
          <TextField source="title" />
          <DateField source="start" options={{ timeZone: "America/Chicago" }} />
          <DateField source="end" options={{ timeZone: "America/Chicago" }} />
          <TextField source="desc" />
        </Datagrid>
      )}
    </List>
  );
};

export default EventList;

import * as React from "react";
import {
  DateTimeInput,
  Edit,
  TextInput,
  SimpleForm,
  BooleanInput,
  ReferenceInput,
  ReferenceArrayInput,
} from "react-admin";
import { Grid, Box, Typography } from "@mui/material";

import { validateForm } from "./TeamCreate";

const TeamEdit = () => {
  return (
    <Edit>
      <SimpleForm validate={validateForm}>
        <TextInput source="category" />
        <TextInput source="name" />
        {/* <ReferenceArrayInput source="playerIds" reference="players">
                <TextInput source="id" />
            </ReferenceArrayInput> */}
        <TextInput source="id" />
      </SimpleForm>
    </Edit>
  );
};

export default TeamEdit;

import {
  Datagrid,
  DateField,
  ReferenceArrayField,
  ReferenceField,
  DateInput,
  List,
  TextField,
  SearchInput,
} from "react-admin";
import { useMediaQuery, Theme } from "@mui/material";

import MobileGrid from "./MobileGrid";
import TeamListAside from "./TeamListAside";

const TeamFilters = [
  <SearchInput source="q" alwaysOn />,
  <DateInput source="last_seen_gte" />,
];

/**
 * Team List
 *
 * List of all teams in the database, with details such as name, practice time, and
 * assigned coaches and players.
 *
 * @function TeamList
 * @returns {JSX.Element} The Team List component.
 */
const TeamList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm"),
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  return (
    <List
      filters={isSmall ? TeamFilters : undefined}
      sort={{ field: "end", order: "DESC" }}
      perPage={25}
      aside={<TeamListAside />}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            "& .column-groups": {
              md: { display: "none" },
              lg: { display: "table-cell" },
            },
          }}
        >
          /** * The team name. */
          <TextField source="name" />
          /** * The practice time for this team. */
          <TextField source="practiceTime" />
        </Datagrid>
      )}
    </List>
  );
};

export default TeamList;

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PlayerList from "./PlayerList";
import PlayerCreate from "./PlayerCreate";
import PlayerEdit from "./PlayerEdit";

const resource = {
  list: PlayerList,
  create: PlayerCreate,
  edit: PlayerEdit,
  icon: CalendarMonthIcon,
  recordRepresentation: (record: any) => `${record.title}`,
};

export default resource;

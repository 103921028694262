import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import VolunteerList from "./VolunteerList";
import VolunteerCreate from "./VolunteerCreate";
import VolunteerEdit from "./VolunteerEdit";

const resource = {
  list: VolunteerList,
  create: VolunteerCreate,
  edit: VolunteerEdit,
  icon: CalendarMonthIcon,
  recordRepresentation: (record: any) => `${record.title}`,
};

export default resource;

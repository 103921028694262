import axios from "axios";
import authHeader from "./auth-header";
import Backend from "../utilities/Backend";

const API_URL = `${Backend}/user/`;

const UserService = new (class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getUser() {
    return axios.get(API_URL + "info", { headers: authHeader() });
  }

  getPlayers() {
    return axios.get(API_URL + "playerinfo", { headers: authHeader() });
  }

  sendMessage(subject: string, message: string, teamId: string) {
    return axios.post(
      API_URL + "message/",
      {
        subject,
        message,
        teamId,
      },
      { headers: authHeader() },
    );
  }

  userInfoUpdate(
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    address: string,
    address2: string,
    city: string,
    state: string,
    zip: string,
    isMember: boolean,
  ) {
    return axios
      .post(
        API_URL + "info",
        {
          email,
          firstName,
          lastName,
          phone,
          address,
          address2,
          city,
          state,
          zip,
          isMember,
        },
        {
          headers: authHeader(),
        },
      )
      .then((response) => {
        if (response.data.storageData) {
          localStorage.setItem(
            "user",
            JSON.stringify(response.data.storageData),
          );
        }
        return response.data;
      });
  }

  userPreferenceUpdate(textPreference?: boolean, emailPreference?: boolean, textConsent?: boolean) {
    return axios.post(
      API_URL + "preferences",
      {
        textPreference,
        emailPreference,
        textConsent
      },
      {
        headers: authHeader(),
      },
    );
  }

  userReminderUpdate(practiceReminder: boolean, gameReminder: boolean) {
    return axios.post(
      API_URL + "reminders",
      {
        practiceReminder,
        gameReminder,
      },
      {
        headers: authHeader(),
      },
    );
  }

  upload(formData: FormData) {
    return axios.post(API_URL + "upload", formData).then((response) => {
      return response.data;
    });
  }
})();

export default UserService;

import { useState, useEffect, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import VolunteerService from "../services/volunteer.service";
import AuthService from "../services/auth.service";
import AdminService from "../services/admin.service";
import EventBus from "../utilities/EventBus";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Typography,
  TextField,
} from "@mui/material";
import { Subject } from "@mui/icons-material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const validationSchema = () => {
  return Yup.object({
    subject: Yup.string().required("Subject cannot be blank"),
    message: Yup.string().required("Message cannot be blank"),
  });
};

const TeamCommunication = () => {
  const [redirect, setRedirect] = useState("");
  const [open, setOpen] = useState(false);
  const [sent, setSent] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [messageRow, setMessageRow] = useState(0);
  const [data, setData] = useState([{ team: "default", players: [] }]);
  const [retrievedData, setRetrievedData] = useState(false);
  const [content, setContent] = useState([
    {
      rank: 0,
      firstName: "default",
      lastName: "default",
      parentFirstName: "default",
      parentLastName: "default",
      parentPhone: "default",
      parentEmail: "default",
    },
  ]);
  const location = useLocation().pathname;

  const [rows, setRows] = useState([
    {
      name: "default",
      parentName: "string",
      parentEmail: "string",
      parentPhone: "string",
      parentId: "string",
    },
  ]);

  function createData(
    name: string,
    parentName: string,
    parentEmail: string,
    parentPhone: string,
    parentId: string,
  ) {
    return { name, parentName, parentEmail, parentPhone, parentId };
  }

  const rowData = (index: number) => {
    if (data[index] && data[index].players.length > 0) {
      const playerList = data[index].players.map(
        (player: any, index: number) => ({
          rank: player.rank,
          name: player.firstName + " " + player.lastName,
          parentName:
            player.parentId.firstName + " " + player.parentId.lastName,
          parentPhone: player.parentId.phone,
          parentEmail: player.parentId.email,
          parentId: player.parentId.id,
        }),
      );

      let currentRows: any[] = [];
      playerList.forEach(
        (player: {
          name: string;
          parentName: string;
          parentEmail: string;
          parentPhone: string;
          parentId: string;
        }) => {
          currentRows.push(
            createData(
              player.name,
              player.parentName,
              player.parentEmail,
              player.parentPhone,
              player.parentId,
            ),
          );
        },
      );
      setRows(currentRows);
    }
  };

  useEffect(() => {
    if (location === "/teamcommunication") {
      if (!retrievedData) {
        const currentUser = AuthService.getAuthenticated();

        if (!currentUser) {
          setRedirect("/login");
        }
        const isAdmin = AuthService.isAdmin();
        if (isAdmin) {
          AdminService.getTeams().then(
            (response) => {
              setRetrievedData(true);
              setData(response.data);
            },
            (error) => {
              console.error("erred retrieving players");
              setContent(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  error.message ||
                  error.toString(),
              );

              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
                setRedirect("/");
              }
            },
          );
        } else {
          VolunteerService.getTeams().then(
            (response) => {
              setRetrievedData(true);
              setData(response.data);
            },
            (error) => {
              console.error("erred retrieving players");
              setContent(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  error.message ||
                  error.toString(),
              );

              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
                setRedirect("/");
              }
            },
          );
        }
      }
      rowData(index);
    }
  }, [index, data]);

  const formik = useFormik({
    initialValues: {
      subject: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setSent(true);
      let parentIds: string[] = [];

      if (messageRow === -1) {
        const parents = data[index].players.map((player) => {
          //@ts-ignore
          return player.parentId.id;
        });
        parentIds = parents;
      } else {
        //@ts-ignore
        parentIds = [data[index].players[messageRow].parentId.id];
      }
      await VolunteerService.sendMessage(
        values.subject,
        values.message,
        parentIds,
      ).then(
        //@ts-ignore
        (response) => {
          setSent(false);
          setSuccessOpen(true);
          resetForm();
          setTimeout(() => {
            setSuccessOpen(false);
            setOpen(false);
          }, 3000);
        },
        //@ts-ignore
        (error) => {
          if (error.response.status === 409) {
            setSent(false);
          }
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        },
      );
    },
  });

  const handleSuccessClose = () => {
    setSuccessOpen(false);
    setSent(false);
  };

  const handleOpen = (row: number) => {
    setMessageRow(row);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  if (redirect !== "") {
    return <Navigate to={redirect} />;
  }
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="team-select-label">Team</InputLabel>
            <Select
              labelId="team-select-label"
              id="team"
              required
              displayEmpty
              value={index}
              name="team"
              label="Team"
              //@ts-ignore
              onChange={(e) => setIndex(e.target.value)}
            >
              {data.map(({ team }, index) => (
                <MenuItem key={team} value={index}>
                  {team}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {retrievedData && (!data[index] || data[index].players.length === 0) && (
        <Typography
          color="inherit"
          align="center"
          variant="h2"
          sx={{ zIndex: 2, mb: 10 }}
        >
          No players are assigned to this team yet.
        </Typography>
      )}
      {retrievedData && data[index] && data[index].players.length > 0 && (
        <>
          <Button
            variant="contained"
            sx={{ margin: "20px" }}
            onClick={() => handleOpen(-1)}
          >
            Message All
          </Button>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Player</TableCell>
                  <TableCell align="center">Parent Name</TableCell>
                  <TableCell align="center">Parent Email</TableCell>
                  <TableCell align="center">Parent Phone</TableCell>
                  <TableCell align="center">Contact</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.parentName}</TableCell>
                    <TableCell align="center">{row.parentEmail}</TableCell>
                    <TableCell align="center">{row.parentPhone}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        onClick={() => handleOpen(index)}
                      >
                        Message
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <form onSubmit={formik.handleSubmit} noValidate>
              <Box sx={style}>
                <TextField
                  id="outlined-basic"
                  label="Subject"
                  name="subject"
                  variant="outlined"
                  fullWidth
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.subject && Boolean(formik.errors.subject)
                  }
                  helperText={formik.touched.subject && formik.errors.subject}
                  required
                />

                <TextField
                  id="message"
                  name="message"
                  label="Message"
                  fullWidth
                  multiline
                  rows={4}
                  placeholder="Message to team"
                  variant="filled"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.message && Boolean(formik.errors.message)
                  }
                  helperText={formik.touched.message && formik.errors.message}
                  required
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ marginRight: "5px", minWidth: "125px" }}
                >
                  {sent ? "Sending..." : "Send"}
                </Button>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  sx={{ marginRight: "5px", minWidth: "125px" }}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </Modal>
          <Modal
            open={successOpen}
            onClose={handleSuccessClose}
            aria-labelledby="success-message"
            aria-describedby="message-success"
          >
            <Box sx={style}>
              <Typography
                id="success-message"
                color="#00e676"
                align="center"
                variant="h6"
              >
                Message Sent!
              </Typography>
            </Box>
          </Modal>
        </>
      )}
    </Box>
  );
};

export default TeamCommunication;

/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Typography from "./modules/components/Typography";
import Button from "@mui/material/Button";

// Material Kit 2 React examples
import DefaultFooter from "./modules/views/AppFooter";

// About Us page sections
import Information from "./modules/views/AboutUsInformation";
import Team from "./modules/views/AboutUsTeam";
import Featuring from "./modules/views/AboutUsFeaturing";
import Newsletter from "./modules/views/AboutUsNewsletter";

// Images
import bgImage from "../images/bg-about-us.jpg";

const AboutUsHeroLayout = styled("section")(({ theme }) => ({
  color: theme.palette.common.white,
  position: "relative",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    height: "80vh",
    minHeight: 500,
    maxHeight: 1300,
  },
}));

const Background = styled(Box)({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  // backgroundSize: 'cover',
  // backgroundRepeat: 'no-repeat',
  zIndex: 0,
});

function AboutUs() {
  return (
    <>
      <AboutUsHeroLayout>
        <Container
          sx={{
            mt: 3,
            mb: 14,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            zIndex={2}
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <Typography
              variant="h2"
              color="white"
              fontWeight="bold"
              // sx={({ breakpoints, typography: { size } }) => ({
              //   [breakpoints.down("md")]: {
              //     fontSize: size["3xl"],
              //   },
              // })}
            >
              Basketball and Cheerleading for all kids
            </Typography>
            <Typography
              variant="body1"
              color="white"
              opacity={0.8}
              mt={1}
              mb={3}
            >
              A win in our book means team building and character development
            </Typography>
            <Button color="secondary" variant="contained" href="/signup">
              create account
            </Button>
          </Grid>
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: "common.black",
              opacity: 0.4,
              zIndex: 1,
            }}
          />
          <Background
            minHeight="75vh"
            width="100%"
            sx={{
              backgroundImage: `url(${bgImage})`,
              // backgroundColor: '#7fc7d9', // Average color of the background image.
              backgroundPosition: "center",
            }}
          />
        </Container>
      </AboutUsHeroLayout>
      <Card
        sx={{
          zIndex: 10,
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: { xs: 0, lg: -8 },
          mb: 4,
          // boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        <Team />
        <Featuring />
        <Newsletter />
      </Card>
      <Box pt={6} px={1} mt={6}>
        <DefaultFooter />
      </Box>
    </>
  );
}

export default AboutUs;

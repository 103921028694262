import * as React from "react";
import { SvgIconComponent } from "@mui/icons-material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";

import CardWithIcon from "./CardWithIcon";

interface Props {
  value?: string;
  title?: string;
  link?: string;
  icon?: any;
}

const Card = (props: Props) => {
  const { value, title, icon, link } = props;
  return (
    <CardWithIcon
      to={link ? link : "/"}
      icon={icon ? icon : InsertPhotoIcon}
      title={title}
      subtitle={value}
    />
  );
};

export default Card;

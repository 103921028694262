import * as React from "react";
import {
  Create,
  DateTimeInput,
  SimpleForm,
  TextInput,
  BooleanInput,
} from "react-admin";
import { Box, Typography } from "@mui/material";

export const validateForm = (
  values: Record<string, any>,
): Record<string, any> => {
  const errors = {} as any;
  if (!values.start) {
    errors.start = "ra.validation.required";
  }
  if (!values.end) {
    errors.end = "ra.validation.required";
  }
  values.end;
  if (!values.title) {
    errors.title = "ra.validation.required";
  }
  return errors;
};

//TODO This page is not updated
const TeamCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm
        sx={{ maxWidth: 500 }}
        // Here for the GQL provider
        defaultValues={{
          start: new Date(),
          end: new Date(),
          allDay: true,
          title: "",
          desc: "",
        }}
        validate={validateForm}
      >
        <Typography variant="h6" gutterBottom>
          Teams
        </Typography>
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <DateTimeInput
              source="start"
              fullWidth
              helperText={false}
              // value={startDate}
            />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <DateTimeInput source="end" fullWidth helperText={false} />
          </Box>
        </Box>
        <TextInput type="text" source="title" isRequired fullWidth />
        <TextInput type="text" source="desc" fullWidth />
        <BooleanInput isRequired source="allDay" />
      </SimpleForm>
    </Create>
  );
};

const SectionTitle = ({ label }: { label: string }) => {
  return (
    <Typography variant="h6" gutterBottom>
      {label}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

export default TeamCreate;

import {
  Datagrid,
  DateField,
  DateInput,
  NumberField,
  List,
  BooleanField,
  TextField,
  SearchInput,
  FunctionField,
} from "react-admin";
import { useMediaQuery, Theme } from "@mui/material";

import MobileGrid from "./MobileGrid";

const ExpenseFilters = [
  <SearchInput source="q" alwaysOn />,
  <DateInput source="last_seen_gte" />,
];

const ExpenseList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm"),
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  return (
    <List
      filters={isSmall ? ExpenseFilters : undefined}
      sort={{ field: "date", order: "DESC" }}
      perPage={25}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            "& .column-groups": {
              md: { display: "none" },
              lg: { display: "table-cell" },
            },
          }}
        >
          <TextField source="title" />
          <DateField source="date" options={{ timeZone: "America/Chicago" }} />
          <NumberField source="actualAmount" />
          <NumberField source="budgetedAmount" />
          <TextField source="category" />
          <TextField source="vendor" />
          <BooleanField source="reimbursed" />
          <FunctionField
            source="receiptName"
            label="Receipt Attached"
            render={(record: any, source: any) => (
              <BooleanField
                record={{ ...record, receiptName: !!record.receiptName }}
                source={source}
              />
            )}
          />
        </Datagrid>
      )}
    </List>
  );
};

export default ExpenseList;

import * as React from "react";
import { SxProps, Typography } from "@mui/material";
import { memo } from "react";

import { FieldProps, useRecordContext } from "react-admin";
import AvatarField from "./AvatarField";
import { User } from "../../types";

interface Props extends FieldProps<User> {
  size?: string;
  sx?: SxProps;
  playerCounter?: number;
}

const PlayerFullNameField = (props: Props) => {
  const { size, playerCounter } = props;
  playerCounter === undefined ? 0 : playerCounter;
  const record = useRecordContext<User>();
  return record ? (
    <Typography
      variant="body2"
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      component="div"
      sx={props.sx}
    >
      <AvatarField
        //@ts-ignore
        record={record.registeredPlayers[playerCounter]}
        size={size}
        sx={{
          mr: 1,
          mt: -0.5,
          mb: -0.5,
        }}
      />
      {
        //@ts-ignore
        record.registeredPlayers[playerCounter].firstName
      }{" "}
      {
      //@ts-ignore
      record.registeredPlayers[playerCounter].lastName
      }
    </Typography>
  ) : null;
};

PlayerFullNameField.defaultProps = {
  source: "registeredPlayers" as const,
  label: "resources.users.fields.players",
};

export default memo<Props>(PlayerFullNameField);

import * as React from "react";
import { Card, CardHeader, CardContent } from "@mui/material";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { format, subDays, addDays } from "date-fns";

import { Player } from "../types";

const lastDay = new Date();
const lastMonthDays = Array.from({ length: 30 }, (_, i) => subDays(lastDay, i));
const aMonthAgo = subDays(new Date(), 30);

const dateFormatter = (date: number): string =>
  new Date(date).toLocaleDateString();

const aggregatePlayersByDay = (players: Player[]): { [key: string]: number } =>
  players
    .filter((player: Player) => player.fullyPaid)
    .reduce(
      (acc, curr) => {
        const day = format(new Date(curr.date), "yyyy-MM-dd");
        if (!acc[day]) {
          acc[day] = 0;
        }
        acc[day] += 1;
        return acc;
      },
      {} as { [key: string]: number },
    );

const getRegistrationsPerDay = (players: Player[]): TotalByDay[] => {
  const daysWithRevenue = aggregatePlayersByDay(players);
  return lastMonthDays.map((date) => ({
    date: date.getTime(),
    total: daysWithRevenue[format(new Date(date), "yyyy-MM-dd")] || 0,
  }));
};

const RegistrationChart = (props: { players?: Player[] }) => {
  const { players } = props;
  if (!players) return null;

  return (
    <Card>
      <CardHeader title={"Recent Registrations"} />
      <CardContent>
        <div style={{ width: "100%", height: 300 }}>
          <ResponsiveContainer>
            <AreaChart data={getRegistrationsPerDay(players)}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                name="Date"
                type="number"
                scale="time"
                domain={[addDays(aMonthAgo, 1).getTime(), new Date().getTime()]}
                tickFormatter={dateFormatter}
              />
              <YAxis dataKey="total" name="Registrations" allowDecimals={false} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                cursor={{ strokeDasharray: "3 3" }}
                formatter={(value: any) =>
                  new Intl.NumberFormat(undefined, {
                  }).format(value)
                }
                labelFormatter={(label: any) => dateFormatter(label)}
              />
              <Area
                type="monotone"
                dataKey="total"
                stroke="#8884d8"
                strokeWidth={2}
                fill="url(#colorUv)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

interface TotalByDay {
  date: number;
  total: number;
}

export default RegistrationChart;

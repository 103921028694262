import * as React from "react";
import { Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import {
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  FormGroup,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNotify } from "react-admin";
import Divider from "@mui/material/Divider";
import Typography from "./modules/components/Typography";
import AppFooter from "./modules/views/AppFooter";
import AppForm from "./modules/views/AppForm";
import FormButton from "./modules/form/FormButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MuiPhoneNumber from "mui-phone-number";
import AuthService from "../services/auth.service";
import { phone } from "phone";

const phoneRegExp =
  /^(\+[1-9]{1,4}[ \\-]*)(\([0-9]{2,3}\)[ \\-]*)[0-9]{3,4}?[ \\-]*[0-9]{4}?$/;
const passwordSpecialCharacters = /^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/;
const validationSchema = () => {
  return Yup.object({
    firstName: Yup.string().required("First name cannot be blank"),
    lastName: Yup.string().required("Last name cannot be blank"),
    email: Yup.string()
      .email("This is not a valid email.")
      .required("Email cannot be blank"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number cannot be blank"),
    password: Yup.string()
      .min(8, "Password should be of minimum 8 characters length")
      .matches(
        passwordSpecialCharacters,
        "Needs to include a special character",
      )
      .required("Password is required!"),
    confirmPassword: Yup.string()
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      })
      .required("This field is required!"),
    address: Yup.string().required("Address is required!"),
    city: Yup.string().required("City is required!"),
    zip: Yup.string()
      .min(5, "Zip should be five digits")
      .max(5, "Zip should be five digits")
      .required("Zip is required!"),
  });
};

const SignUp = () => {
  const [redirect, setRedirect] = React.useState("");
  const [sent, setSent] = React.useState(false);
  const notify = useNotify();
  const stringArray: string[] = [];

  const formik = useFormik({
    initialValues: {
      email: "",
      address: "",
      address2: "",
      city: "",
      state: "AL",
      zip: "",
      phone: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      isMember: false,
      advertising: stringArray,
      advertisingOther: "",
      textConsent: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setFieldError }) => {
      setSent(true)
      const formattedPhone = phone(values.phone)
      if (values.advertisingOther !== "")
        values.advertising.push(values.advertisingOther)
      await AuthService.signUp(
        values.firstName,
        values.lastName,
        values.address,
        values.address2,
        values.city,
        values.state,
        values.zip,
        formattedPhone.phoneNumber,
        values.email,
        values.password,
        values.isMember,
        values.advertising,
        values.textConsent,
      ).then(
        //@ts-ignore
        (response) => {
          setRedirect("/");
        },
        //@ts-ignore
        (error) => {
          if (error.response.status === 409) {
            setFieldError("email", "Email already registered");
          }
          setSent(false);
          const message = error.response.data;
          notify(
            typeof message === "string"
              ? message
              : typeof message === "undefined" || !message
                ? "ra.auth.sign_up_error"
                : message,
            {
              type: "error",
              messageArgs: {
                _:
                  typeof message === "string"
                    ? message
                    : error && error.message
                      ? error.message
                      : undefined,
              },
            },
          );
        },
      );
    },
  });

  if (redirect) {
    return <Navigate to={redirect} />;
  }
  // else if (import.meta.env.VITE_APP_BACKEND == "https://upliftbasketball.com") {
  //   return (
  //     <Typography variant="h3">Sorry, we're not yet accepting Sign-ups. Please consider subscribing to our newsletter for future updates</Typography>
  //   )
  // }
  return (
    <React.Fragment>
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Sign Up
          </Typography>
          <Typography variant="body2" align="center">
            <Link href="/signin" underline="always">
              Already have an account?
            </Link>
          </Typography>
        </React.Fragment>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Box sx={{ mt: 6 }}>
            <TextField
              autoComplete="email"
              disabled={sent}
              fullWidth
              label="Email"
              margin="normal"
              name="email"
              required
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  disabled={sent}
                  required
                  name="password"
                  autoComplete="new-password"
                  label="Password"
                  type="password"
                  margin="normal"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  disabled={sent}
                  required
                  name="confirmPassword"
                  autoComplete="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  margin="normal"
                  id="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={sent}
                  fullWidth
                  label="First name"
                  name="firstName"
                  id="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={sent}
                  autoComplete="family-name"
                  fullWidth
                  label="Last name"
                  name="lastName"
                  required
                  id="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
            </Grid>
            <TextField
              autoComplete="address"
              disabled={sent}
              fullWidth
              label="Address"
              margin="normal"
              name="address"
              required
              id="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
            <TextField
              autoComplete="email"
              disabled={sent}
              fullWidth
              label="Address 2"
              margin="normal"
              name="address2"
              id="address2"
              value={formik.values.address2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address2 && Boolean(formik.errors.address2)}
              helperText={formik.touched.address2 && formik.errors.address2}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  disabled={sent}
                  required
                  name="city"
                  autoComplete="city"
                  label="City"
                  margin="normal"
                  id="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>
              <Grid item xs={12} sm={2.5}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="state-select-label">State</InputLabel>
                  <Select
                    labelId="state-select-label"
                    id="state"
                    required
                    // value={state}
                    name="state"
                    label="state"
                    defaultValue="AL"
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="AL">AL</MenuItem>
                    <MenuItem value="AK">AK</MenuItem>
                    <MenuItem value="AR">AR</MenuItem>
                    <MenuItem value="AZ">AZ</MenuItem>
                    <MenuItem value="CA">CA</MenuItem>
                    <MenuItem value="CO">CO</MenuItem>
                    <MenuItem value="CT">CT</MenuItem>
                    <MenuItem value="DE">DE</MenuItem>
                    <MenuItem value="DC">DC</MenuItem>
                    <MenuItem value="FL">FL</MenuItem>
                    <MenuItem value="GA">GA</MenuItem>
                    <MenuItem value="HI">HI</MenuItem>
                    <MenuItem value="ID">ID</MenuItem>
                    <MenuItem value="IL">IL</MenuItem>
                    <MenuItem value="IN">IN</MenuItem>
                    <MenuItem value="IA">IA</MenuItem>
                    <MenuItem value="KS">KS</MenuItem>
                    <MenuItem value="KY">KY</MenuItem>
                    <MenuItem value="LA">LA</MenuItem>
                    <MenuItem value="ME">ME</MenuItem>
                    <MenuItem value="MD">MD</MenuItem>
                    <MenuItem value="MA">MA</MenuItem>
                    <MenuItem value="MN">MN</MenuItem>
                    <MenuItem value="MS">MS</MenuItem>
                    <MenuItem value="MO">MO</MenuItem>
                    <MenuItem value="MT">MT</MenuItem>
                    <MenuItem value="NE">NE</MenuItem>
                    <MenuItem value="NV">NV</MenuItem>
                    <MenuItem value="NH">NH</MenuItem>
                    <MenuItem value="NJ">NJ</MenuItem>
                    <MenuItem value="NM">NM</MenuItem>
                    <MenuItem value="NY">NY</MenuItem>
                    <MenuItem value="NC">NC</MenuItem>
                    <MenuItem value="ND">ND</MenuItem>
                    <MenuItem value="OH">OH</MenuItem>
                    <MenuItem value="OK">OK</MenuItem>
                    <MenuItem value="OR">OR</MenuItem>
                    <MenuItem value="PA">PA</MenuItem>
                    <MenuItem value="PR">PR</MenuItem>
                    <MenuItem value="RI">RI</MenuItem>
                    <MenuItem value="SC">SC</MenuItem>
                    <MenuItem value="SD">SD</MenuItem>
                    <MenuItem value="TN">TN</MenuItem>
                    <MenuItem value="TX">TX</MenuItem>
                    <MenuItem value="UT">UT</MenuItem>
                    <MenuItem value="VT">VT</MenuItem>
                    <MenuItem value="VA">VA</MenuItem>
                    <MenuItem value="WA">WA</MenuItem>
                    <MenuItem value="WV">WV</MenuItem>
                    <MenuItem value="WI">WI</MenuItem>
                    <MenuItem value="WY">WY</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3.5}>
                <TextField
                  fullWidth
                  disabled={sent}
                  required
                  name="zip"
                  autoComplete="zip"
                  label="Zip"
                  margin="normal"
                  id="zip"
                  value={formik.values.zip}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.zip && Boolean(formik.errors.zip)}
                  helperText={formik.touched.zip && formik.errors.zip}
                />
              </Grid>
            </Grid>
            <MuiPhoneNumber
              defaultCountry={'us'}
              onlyCountries={['us']}
              disabled={sent}
              label="Phone"
              margin="normal"
              name="phone"
              required
              id="phone"
              value={formik.values.phone}
              onChange={e => formik.setFieldValue("phone", e)}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
            <FormGroup>
              <FormControlLabel control={<Checkbox onChange={(e: any) => formik.handleChange(e)} name="textConsent" />}
                label={
                  <>
                    <Typography variant="caption" gutterBottom> {"By checking this box, you agree to receive text messages from both coaches and admins of Uplift Basketball related to the league. Message & data rates may apply. Message frequency varies. Reply STOP to cancel or opt out on the profile page. View our "}
                      <Link href="https://upliftbasketball.com/tos">Terms of Service</Link>
                      {" and "}
                      <Link href="https://upliftbasketball.com/privacy"> Privacy Policy</Link>
                    </Typography>
                  </>
                }
              />
            </FormGroup>
            <Divider />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e: any) => formik.handleChange(e)}
                    name="isMember"
                  />
                }
                label="Are you a member of Tannehill Valley?"
              />
            </FormGroup>
            <Divider />
            <FormControl fullWidth>
              <FormLabel id="advertising">How did you hear about us?</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e: any) => formik.handleChange(e)}
                      name="advertising"
                    />
                  }
                  label="Uplift Brochure"
                  value="brochure"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e: any) => formik.handleChange(e)}
                      name="advertising"
                    />
                  }
                  label="McCalla Neighbors Magazine"
                  value="magazine"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e: any) => formik.handleChange(e)}
                      name="advertising"
                    />
                  }
                  label="Word of Mouth"
                  value="WordOfMouth"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e: any) => formik.handleChange(e)}
                      name="advertising"
                    />
                  }
                  label="Church sign by road"
                  value="sign"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e: any) => formik.handleChange(e)}
                      name="advertising"
                    />
                  }
                  label="Email from Uplift"
                  value="email"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e: any) => formik.handleChange(e)}
                      name="advertising"
                    />
                  }
                  label="TVBC Facebook page"
                  value="facebook"
                />
                <TextField
                  label="Other"
                  onChange={(e: any) => formik.handleChange(e)}
                  name="advertisingOther"
                />
              </FormGroup>
              {/* <TextField 
                  fullWidth
                  disabled={sent}
                  required
                  name="advertising"
                  label="Other"
                  type="text"
                  margin="normal"
                  id="other"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  /> */}
            </FormControl>
            <FormButton
              sx={{ mt: 3, mb: 2 }}
              disabled={sent}
              color="secondary"
              fullWidth
            >
              {sent ? "In progress…" : "Sign Up"}
            </FormButton>
          </Box>
          {/* <pre>{JSON.stringify(formik.values, null, 2)}</pre> */}
        </form>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
};

export default SignUp;

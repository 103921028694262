import * as React from "react";
import {
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from "react-admin";

import { Grid, Box, Typography } from "@mui/material";

import FullNameField from "./FullNameField";
import { validateForm } from "./PlayerCreate";

const PlayerEdit = () => {
  return (
    <Edit title={<PlayerName />}>
      <SimpleForm>
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <NumberInput source="rank" />
        <TextInput source="lineUpNumber" />
        <TextInput source="number" />
        <NumberInput source="height" />
        <TextInput source="orderNumber" />
        <TextInput source="gender" />
        <NumberInput source="age" />
        <NumberInput source="grade" />
        <TextInput source="yearsExperience" />
        <TextInput source="nickname" />
        <DateInput source="date" />
        <TextInput source="category" />
        <BooleanInput source="hasSiblings" />
        <NumberInput source="rating" />
      </SimpleForm>
    </Edit>
  );
};

const PlayerName = () => <FullNameField size="32" sx={{ margin: "5px 0" }} />;

export default PlayerEdit;

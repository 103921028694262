import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GroupList from "./GroupList";
import GroupCreate from "./GroupCreate";
import GroupEdit from "./GroupEdit";

const resource = {
  list: GroupList,
  create: GroupCreate,
  edit: GroupEdit,
  icon: CalendarMonthIcon,
  recordRepresentation: (record: any) => `${record.title}`,
};

export default resource;

import { useState, useEffect, Fragment } from "react";
import { Navigate, useLocation } from "react-router-dom";
import VolunteerService from "../services/volunteer.service";
import AuthService from "../services/auth.service";
import AdminService from "../services/admin.service";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import Table from "components/table";
import useLongPress from "../utilities/longPress";
import {
  Button,
  Box,
  Select,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Grid,
  tableCellClasses,
} from "@mui/material";
import EventBus from "../utilities/EventBus";

interface Player {
  rank: number;
  firstName: string;
  lastName: string;
  id: number;
  present: boolean;
  playerId: number;
}

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(1)": {
    backgroundColor: "#7AB4E4",
  },
  "&:nth-of-type(2)": {
    backgroundColor: "#8bc34a",
  },
  "&:nth-of-type(3)": {
    backgroundColor: "#ff9800",
  },
  "&:nth-of-type(5)": {
    backgroundColor: "#ffeb3b",
  },
}));

const Rotation = () => {
  const [redirect, setRedirect] = useState("");
  const [team, setTeam] = useState("");
  const [initialLineup, setInitialLineup] = useState(false);
  const [gameNumber, setGameNumber] = useState(1);
  const [playerNumber, setPlayerNumber] = useState(5);
  const [index, setIndex] = useState(0);
  const [data, setData] = useState([{ team: "default", players: [] }]);
  const [players, setPlayers] = useState([
    {
      id: 0,
      firstName: "default",
      lastName: "default",
      rank: 0,
      present: true,
      playerId: 0,
    },
  ]);
  const [retrievedData, setRetrievedData] = useState(false);
  const [content, setContent] = useState("");
  const [saveSuccessful, setSaveSuccessful] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation().pathname;
  const [rows, setRows] = useState([
    {
      color: "default",
      player1: "string",
      player2: "string",
      player3: "string",
      player4: "string",
      player5: "string",
      player6: "string",
    },
  ]);

  function compare(a: Player, b: Player) {
    if (a.rank < b.rank) {
      return -1;
    }

    if (a.rank > b.rank) {
      return 1;
    }
    return 0;
  }

  const sortPlayers = () => {
    var activePlayerList: any[][] = [[], [], [], [], []];
    var playerIndexList: any[][] = [[], [], [], [], []];
    var rowCounter = 0;
    var rosterCounter = gameNumber - 1;

    let presentPlayers = 0;
    for (let i = 0; i < players.length; i++) {
      if (players[i].present) {
        presentPlayers++;
      }
    }
    if (presentPlayers < playerNumber) {
      setError(true);
      return null;
    }
    for (let i = 0; i < 6; i++) {
      rowCounter = 0;
      while (rowCounter < playerNumber) {
        if (players[rosterCounter].present === true) {
          activePlayerList[rowCounter].push(players[rosterCounter].id);
          playerIndexList[rowCounter].push(rosterCounter);
          rowCounter++;
        }
        rosterCounter++;
        if (rosterCounter >= players.length) {
          rosterCounter = 0;
        }
      }
    }

    for (let k = 0; k < playerNumber; k++) {
      for (let i = 0; i < 6; i++) {
        for (let j = 0; j < 4; j++) {
          if (playerIndexList[j][i] > playerIndexList[j + 1][i]) {
            var playerHolder = activePlayerList[j][i];
            var indexHolder = playerIndexList[j][i];
            playerIndexList[j][i] = playerIndexList[j + 1][i];
            activePlayerList[j][i] = activePlayerList[j + 1][i];
            playerIndexList[j + 1][i] = indexHolder;
            activePlayerList[j + 1][i] = playerHolder;
          }
        }
      }
    }
    return playerIndexList;
  };

  function createData(
    color: string,
    player1: string,
    player2: string,
    player3: string,
    player4: string,
    player5: string,
    player6: string,
  ) {
    return { color, player1, player2, player3, player4, player5, player6 };
  }

  const LineUp = (index: number) => {
    if (data[index] && data[index].players.length > 0) {
      const playerList = data[index].players.map((player: any) => ({
        rank: player.rank,
        firstName: player.firstName,
        lastName: player.lastName,
        playerId: player._id,
        present: true,
        id: player.rank,
      }));
      //@ts-ignore
      const players: [] = playerList.sort(compare);
      setTeam(data[index].team);
      setIndex(index);
      setPlayers(players);
    }
  };

  useEffect(() => {
    if (location === "/rotation") {
      if (!retrievedData) {
        const currentUser = AuthService.getAuthenticated();

        if (!currentUser) {
          setRedirect("/login");
        }
        const isAdmin = AuthService.isAdmin();
        if (isAdmin) {
          AdminService.getTeams().then(
            (response) => {
              setRetrievedData(true);
              setData(response.data);
            },
            (error) => {
              console.error("erred retrieving players");
              setContent(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  error.message ||
                  error.toString(),
              );

              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
                setRedirect("/");
              }
            },
          );
        } else {
          VolunteerService.getTeams().then(
            (response) => {
              setRetrievedData(true);
              setData(response.data);
            },
            (error) => {
              console.error("erred retrieving players");
              setContent(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  error.message ||
                  error.toString(),
              );

              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
                setRedirect("/");
              }
            },
          );
        }
      } else if (!initialLineup) {
        LineUp(index);
        setInitialLineup(true);
      } else if (data[index] && data[0].players.length > 0) {
        const sorted = sortPlayers();
        if (sorted != null) {
          const colors = ["Blue", "Green", "Orange", "White", "Yellow"];
          let rowBuilder = [];
          for (let i = 0; i < playerNumber; i++) {
            rowBuilder.push(
              createData(
                colors[i],
                players[sorted[i][0]].firstName,
                players[sorted[i][1]].firstName,
                players[sorted[i][2]].firstName,
                players[sorted[i][3]].firstName,
                players[sorted[i][4]].firstName,
                players[sorted[i][5]].firstName,
              ),
            );
          }
          setRows(rowBuilder);
        }
      }
    }
    if (saveSuccessful) {
      setTimeout(() => {
        setSaveSuccessful(false);
      }, 3000);
    }
  }, [players, gameNumber, playerNumber, saveSuccessful, data, index]);

  const onLongPress = async (event: any) => {
    setError(false);
    const itemId = event.target.id;
    const tempPlayers = players.slice();
    tempPlayers[itemId - 1].present = !players[itemId - 1].present;
    setPlayers(tempPlayers);
  };

  // Short clicks are not needed
  const onClick = () => {
    console.log("click is triggered");
  };

  const onGameChange = (event: any) => {
    setError(false);
    setGameNumber(Number(event.target.value));
  };

  const onPlayerChange = (event: any) => {
    setError(false);
    setPlayerNumber(Number(event.target.value));
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };
  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);
  if (redirect !== "") {
    return <Navigate to={redirect} />;
  }
  return (
    <Fragment>
      <Grid container spacing={1} marginBottom={2}>
        <Grid item xs={3} sm={4} md={2}>
          <Box sx={{ paddingLeft: "9px", paddingTop: "8px" }}>
            <FormControl fullWidth>
              <InputLabel id="week-selector-label">Week</InputLabel>
              <Select
                labelId="week-selector-label"
                id="week-selector"
                defaultValue={1}
                label="Week"
                onChange={onGameChange}
              >
                <MenuItem value={1}>Week 1</MenuItem>
                <MenuItem value={2}>Week 2</MenuItem>
                <MenuItem value={3}>Week 3</MenuItem>
                <MenuItem value={4}>Week 4</MenuItem>
                <MenuItem value={5}>Week 5</MenuItem>
                <MenuItem value={6}>Week 6</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={3} sm={4} md={2}>
          <Box sx={{ paddingLeft: "9px", paddingTop: "8px" }}>
            <FormControl fullWidth>
              <InputLabel id="player-number-selector-label">
                Number of Players
              </InputLabel>
              <Select
                labelId="player-number-selector-label"
                id="player-number-selector"
                defaultValue={5}
                label="Number of Players"
                onChange={onPlayerChange}
              >
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6.1} sm={4} md={2}>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="team-select-label">Team</InputLabel>
            <Select
              labelId="team-select-label"
              id="team"
              required
              displayEmpty
              value={index}
              name="team"
              label="Team"
              //@ts-ignore
              onChange={(e) => LineUp(e.target.value)}
            >
              {data.map(({ team }, index) => (
                <MenuItem key={team} value={index}>
                  {team}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {retrievedData && players[0].firstName === "default" && (
        <Typography
          color="inherit"
          align="center"
          variant="h2"
          sx={{ zIndex: 2, mb: 10 }}
        >
          No players are assigned to you yet.
        </Typography>
      )}
      {retrievedData && players[0].firstName !== "default" && (
        <>
          <Box style={{ maxWidth: 400 }}>
            {players.map((player) => (
              <Box
                {...longPressEvent}
                key={player.id.toString()}
                id={player.id.toString()}
                bgcolor={player.present ? "chartreuse" : "lightpink"}
                sx={{
                  mb: 2,
                  height: 40,
                  width: 1,
                  textAlign: "center",
                  typography: "h6",
                }}
                component="button"
              >
                {player.firstName + " " + player.lastName}
              </Box>
            ))}
          </Box>

          {error ? (
            <Typography
              color="inherit"
              align="center"
              variant="h6"
              sx={{ zIndex: 2, mb: 10 }}
            >
              Number of available players is less than selected team size. Lower
              the number of players
            </Typography>
          ) : (
            <Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Color</TableCell>
                      <TableCell align="right">Period 1</TableCell>
                      <TableCell align="right">Period 2</TableCell>
                      <TableCell align="right">Period 3</TableCell>
                      <TableCell align="right">Period 4</TableCell>
                      <TableCell align="right">Period 5</TableCell>
                      <TableCell align="right">Period 6</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow
                        key={row.color}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.color}
                        </TableCell>
                        <TableCell align="center">{row.player1}</TableCell>
                        <TableCell align="center">{row.player2}</TableCell>
                        <TableCell align="center">{row.player3}</TableCell>
                        <TableCell align="center">{row.player4}</TableCell>
                        <TableCell align="center">{row.player5}</TableCell>
                        <TableCell align="center">{row.player6}</TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </>
      )}
    </Fragment>
  );
};

export default Rotation;

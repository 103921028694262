import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  BooleanInput,
} from "react-admin";
import { Box, Typography } from "@mui/material";

//Lazy loads the RichText library
const RichTextInput = React.lazy(() =>
  import("ra-input-rich-text").then((module) => ({
    default: module.RichTextInput,
  })),
);

export const validateForm = (
  values: Record<string, any>,
): Record<string, any> => {
  const errors = {} as any;
  if (!values.to) {
    errors.to = "ra.validation.required";
  }
  if (!values.subject) {
    errors.subject = "ra.validation.required";
  }
  if (!values.message) {
    errors.message = "ra.validation.required";
  }
  return errors;
};

const CommunicationCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm
        // sx={{ maxWidth: 500 }}
        // Here for the GQL provider
        defaultValues={{
          to: "",
          from: "",
          subject: "",
          Message: "",
        }}
        validate={validateForm}
      >
        <Typography variant="h6" gutterBottom>
          Communications
        </Typography>
        <RadioButtonGroupInput
          source="group"
          isRequired
          choices={[
            { id: "newsletter", name: "Newsletter" },
            { id: "coaches", name: "Coaches" },
            { id: "parents", name: "Parents" },
          ]}
        />
        <TextInput type="text" source="subject" isRequired />
        <RichTextInput source="message" fullWidth isRequired />
        <BooleanInput source="emailOnly" />
      </SimpleForm>
    </Create>
  );
};

const SectionTitle = ({ label }: { label: string }) => {
  return (
    <Typography variant="h6" gutterBottom>
      {label}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

export default CommunicationCreate;

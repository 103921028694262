import * as React from "react";
import {
  Create,
  DateTimeInput,
  SimpleForm,
  TextInput,
  BooleanInput,
} from "react-admin";
import { Box, Typography } from "@mui/material";

export const validateForm = (
  values: Record<string, any>,
): Record<string, any> => {
  const errors = {} as any;
  if (!values.start) {
    errors.start = "ra.validation.required";
  }
  if (!values.end) {
    errors.end = "ra.validation.required";
  }
  values.end;
  if (!values.title) {
    errors.title = "ra.validation.required";
  }
  return errors;
};

//TODO Build this page out to manually create a volunteer
const EventCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm
        sx={{ maxWidth: 500 }}
        // Here for the GQL provider
        defaultValues={{
          userEmail: "",
          allDay: true,
          title: "",
          desc: "",
        }}
        validate={validateForm}
      >
        <TextInput source="userId.firstName" />
      <TextInput source="userId.lastName" />
      <TextInput source="email" />
      <TextInput source="phone" />
      <TextInput source="ageGroups" />
      <TextInput source="assignedGroup" />
      <TextInput source="volunteerTasks" />
      <TextInput source="poloSize" />
      {/* <DateInput source="coachMeeting" />
      <DateInput source="year" />
      <DateInput source="ballOrCheer" />
      <DateInput source="practiceTimePreferences" />
      <ReferenceArrayInput source="teamIds" reference="teams">
        <TextInput source="id" />
      </ReferenceArrayInput> */}
      <BooleanInput source="isAsstCoach" />
      <BooleanInput source="isCoach" />
      <BooleanInput source="isReferee" />
      <BooleanInput source="isVolunteer" />
      </SimpleForm>
    </Create>
  );
};

const SectionTitle = ({ label }: { label: string }) => {
  return (
    <Typography variant="h6" gutterBottom>
      {label}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

export default EventCreate;

import * as React from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "../components/Button";
import Typography from "../components/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import Court from "../../../images/basketball-court.svg?react";
import Goal from "../../../images/basketball-goal.svg?react";
import Jersey from "../../../images/basketball-jersey.svg?react";
import Background from "../../../images/productCurvyLines.png";
const item: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: "default",
  color: "secondary.main",
  fontWeight: "medium",
};

const image = {
  height: 55,
  my: 4,
};

function ProductHowItWorks() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "background", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={Background}
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 14 }}>
          How it works
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>1. Coach Camps</Box>
                <SvgIcon
                  component={Court}
                  inheritViewBox
                  sx={{ width: "80px", height: "80px", my: 4 }}
                />

                <Typography variant="h5" align="center">
                  Camps to let coaches instruct on the basics of basketball and
                  cheer. Kids will rotate through stations that teach different
                  fundamentals
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>2. Practice</Box>
                <SvgIcon
                  component={Goal}
                  inheritViewBox
                  sx={{ width: "80px", height: "80px", my: 4 }}
                />

                <Typography variant="h5" align="center">
                  After getting assigned to a team, there are weekly team
                  practices to further develop skills
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>3. GameTime</Box>
                <SvgIcon
                  component={Jersey}
                  inheritViewBox
                  sx={{ width: "80px", height: "80px", my: 4 }}
                />

                <Typography variant="h5" align="center">
                  6 Games to show off what they have learned. No favoritism
                  allowed since play time is <b>equally</b> divided between all
                  players
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <Button
          color="secondary"
          size="large"
          variant="contained"
          component="a"
          href="/register"
          sx={{ mt: 8 }}
        >
          Get started
        </Button>
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;

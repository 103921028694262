import * as React from "react";
import {
  DateTimeInput,
  Edit,
  TextInput,
  SimpleForm,
  BooleanInput,
} from "react-admin";
import { Grid, Box, Typography } from "@mui/material";

import { validateForm } from "./EventCreate";

const EventEdit = () => {
  return (
    <Edit>
      <SimpleForm validate={validateForm}>
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom>
                Events
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <DateTimeInput source="start" fullWidth helperText={false} />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <DateTimeInput source="end" fullWidth helperText={false} />
                </Box>
              </Box>
              <TextInput type="text" source="title" isRequired fullWidth />
              <TextInput type="text" source="desc" fullWidth />
              <BooleanInput isRequired source="allDay" />

              <Box mt="1em" />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default EventEdit;

import { fetchUtils, CreateParams, UpdateParams } from "react-admin";
import { stringify } from "query-string";
import authHeader from "./services/auth-header";
import Backend from "./utilities/Backend";

const API_URL = `${Backend}`;
const httpClient = fetchUtils.fetchJson;

export default {
  getList: async (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${API_URL}/admin/${resource}?${stringify(query)}`;
    const { json, headers } = await httpClient(url, {
      headers: new Headers(authHeader()),
    });
    return {
      data: json,
      //@ts-ignore
      total: parseInt(headers.get("X-Total-Count"), 10),
    };
  },

  getOne: async (resource: string, params: any) => {
    const url = `${API_URL}/admin/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      headers: new Headers(authHeader()),
    });
    if (resource === "expenses" && json.receiptUrl) {
      json.receipt = { src: json.receiptUrl, title: json.receiptName };
    }
    return { data: json };
  },

  getMany: async (resource: string, params: any) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${API_URL}/admin/${resource}?${stringify(query)}`;
    const { json } = await httpClient(url, {
      headers: new Headers(authHeader()),
    });
    return { data: json };
  },

  getManyReference: async (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${API_URL}/admin/${resource}?${stringify(query)}`;
    const { json, headers } = await httpClient(url, {
      headers: new Headers(authHeader()),
    });
    return {
      data: json,
      //@ts-ignore
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    };
  },

  create: async (resource: string, params: any) => {
    const { json } = await httpClient(`${API_URL}/admin/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
      headers: new Headers(authHeader()),
    });
    if (resource === "expenses" && params.data.receipt) {
      const formData = createExpenseFormData(params);
      formData.append("expenseId", json.id);
      const upload = await httpClient(`${API_URL}/admin/${resource}/receipt`, {
        method: "POST",
        body: formData,
        headers: new Headers(authHeader()),
      });
      json.receipt = upload.json.url;
    }
    return { data: json };
  },

  update: async (resource: string, params: any) => {
    const url = `${API_URL}/admin/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      body: JSON.stringify(params.data),
      headers: new Headers(authHeader()),
    });
    if (
      resource === "expenses" &&
      params.data.receipt &&
      params.data.receipt.title !== json.receiptName
    ) {
      const formData = createExpenseFormData(params);
      formData.append("expenseId", json.id);
      const upload = await httpClient(`${API_URL}/admin/${resource}/receipt`, {
        method: "POST",
        body: formData,
        headers: new Headers(authHeader()),
      });
      json.receipt = upload.json.url;
    }
    return { data: json };
  },

  updateMany: async (resource: string, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${API_URL}/admin/${resource}?${stringify(query)}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      body: JSON.stringify(params.data),
      headers: new Headers(authHeader()),
    });
    return { data: json };
  },

  delete: async (resource: string, params: any) => {
    const url = `${API_URL}/admin/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      method: "DELETE",
      headers: new Headers(authHeader()),
    });
    return { data: json };
  },

  deleteMany: async (resource: string, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${API_URL}/admin/${resource}?${stringify(query)}`;
    const { json } = await httpClient(url, {
      method: "DELETE",
      body: JSON.stringify(params.data),
      headers: new Headers(authHeader()),
    });
    return { data: json };
  },
};

type ExpenseParams = {
  id: string;
  title: string;
  description: string;
  receipt: {
    rawFile: File;
    src?: string;
    title?: string;
  };
};

const createExpenseFormData = (
  params: CreateParams<ExpenseParams> | UpdateParams<ExpenseParams>,
) => {
  const formData = new FormData();
  params.data.receipt?.rawFile &&
    formData.append("file", params.data.receipt.rawFile);
  params.data.receipt?.title &&
    formData.append("title", params.data.receipt.title);
  params.data.description &&
    formData.append("content", params.data.description);

  return formData;
};

import * as React from "react";
import {
  DateInput,
  Edit,
  BooleanInput,
  TextInput,
  PasswordInput,
  SimpleForm,
  useTranslate,
  CheckboxGroupInput,
} from "react-admin";
import { Grid, Box, Typography } from "@mui/material";

import Aside from "./Aside";
import FullNameField from "./FullNameField";
import { validateForm } from "./VisitorCreate";

const VisitorEdit = () => {
  return (
    <Edit title={<VisitorTitle />} aside={<Aside />}>
      <SimpleForm validate={validateForm}>
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom>
                Identity
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="firstName" isRequired fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="lastName" isRequired fullWidth />
                </Box>
              </Box>
              <TextInput type="email" source="email" isRequired fullWidth />
              <Box mt="1em" />

              <Typography variant="h6" gutterBottom>
                address
              </Typography>
              <TextInput
                source="address"
                multiline
                fullWidth
                helperText={false}
              />
              <TextInput
                source="address2"
                multiline
                fullWidth
                helperText={false}
              />
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="city" fullWidth helperText={false} />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="state" fullWidth helperText={false} />
                </Box>
                <Box flex={2}>
                  <TextInput source="zip" fullWidth helperText={false} />
                </Box>
              </Box>

              <Box mt="1em" />

              <Typography variant="h6" gutterBottom>
                Change Password
              </Typography>
              {/* TODO finish building out reset PW */}
              <CheckboxGroupInput
                source="resetPassword"
                choices={[
                  { id: "resetPw", name: "Send reset Password Email?" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <BooleanInput source="newsletter" />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  );
};

const VisitorTitle = () => <FullNameField size="32" sx={{ margin: "5px 0" }} />;

export default VisitorEdit;

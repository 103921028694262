import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ExpenseList from "./ExpenseList";
import ExpenseCreate from "./ExpenseCreate";
import ExpenseEdit from "./ExpenseEdit";

const resource = {
  list: ExpenseList,
  create: ExpenseCreate,
  edit: ExpenseEdit,
  icon: CalendarMonthIcon,
  recordRepresentation: (record: any) => `${record.title}`,
};

export default resource;

import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import UserService from "../services/user.service";
import AppForm from "./modules/views/AppForm";

const PhotoUpload = () => {
  const [submitted, setSubmitted] = useState(false);
  const [imagePreviews, setImagePreviews] = useState<Array<string>>([]);
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [message, setMessage] = useState<Array<string>>([]);

  const upload = (idx: number, file: File) => {
    const formData = new FormData();
    formData.append("photo", file);
    return UserService.upload(formData)
      .then(() => {
        setMessage((prevMessage) => [
          ...prevMessage,
          file.name + ": Successful!",
        ]);
      })
      .catch((err: any) => {
        let msg = file.name + ": Failed!";
        if (err.response && err.response.data && err.response.data.message) {
          msg += " " + err.response.data.message;
        }

        setMessage((prevMessage) => [...prevMessage, msg]);
      });
  };
  const uploadImages = () => {
    if (selectedFiles != null) {
      const files = Array.from(selectedFiles);
      const uploadPromises = files.map((file, i) => upload(i, file));

      //   Promise.all(uploadPromises)
      //     .then(() => UserService.getFiles())
      //     .then((images) => {
      //       setImageInfos(images.data);
      //     });
      setSubmitted(true);

      setMessage([]);
    }
  };

  const selectImages = (event: React.ChangeEvent<HTMLInputElement>) => {
    let images: Array<string> = [];
    let files = event.target.files;

    if (files) {
      for (let i = 0; i < files.length; i++) {
        images.push(URL.createObjectURL(files[i]));
      }

      setSelectedFiles(files);
      setImagePreviews(images);
      setMessage([]);
    }
  };
  if (submitted) {
    return (
      <Box component="section" bgcolor="background">
        <Container
          sx={{
            mt: 10,
            mb: 15,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" component="h2" sx={{ mb: 4 }}>
            Thanks for your submission!
          </Typography>
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              setSubmitted(false);
              setSelectedFiles(null);
              setImagePreviews([]);
            }}
          >
            Upload More
          </Button>
        </Container>
      </Box>
    );
  }
  return (
    <Box component="section" bgcolor="background">
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" component="h2" sx={{ mb: 4 }}>
          Share your greatest shots!
        </Typography>
        <form encType="multipart/form-data">
          <Button variant="contained" component="label">
            Select Image(s)
            <input
              type="file"
              accept="image/*"
              name="photo"
              hidden
              multiple
              onChange={selectImages}
            />
          </Button>
          <Button
            sx={{ marginLeft: "10px" }}
            variant="contained"
            onClick={uploadImages}
          >
            Submit
          </Button>
        </form>

        {imagePreviews && (
          <div>
            {imagePreviews.map((img, i) => {
              return (
                <img className="preview" src={img} alt={"image-" + i} key={i} />
              );
            })}
          </div>
        )}
      </Container>
    </Box>
  );
};

export default PhotoUpload;

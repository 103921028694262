import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import LabelIcon from "@mui/icons-material/Label";
import PagesIcon from "@mui/icons-material/Pages";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SportsIcon from "@mui/icons-material/Sports";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ScreenRotationAltIcon from "@mui/icons-material/ScreenRotationAlt";
import SportsHandballIcon from "@mui/icons-material/SportsHandball";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import GroupsIcon from "@mui/icons-material/Groups";
import SettingsIcon from "@mui/icons-material/Settings";
import EmailIcon from "@mui/icons-material/Email";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import {
  MenuItemLink,
  MenuProps,
  useSidebarState,
  DashboardMenuItem,
} from "react-admin";

import users from "../adminTools/users";
// import orders from '../orders';
// import invoices from '../invoices';
// import products from '../products';
// import categories from '../categories';
// import reviews from '../reviews';
import SubMenu from "./SubMenu";
import { EditCalendar } from "@mui/icons-material";

type MenuName =
  | "menuCoachPages"
  | "menuAdminPages"
  | "menuCustomers"
  | "menuCustomPages"
  | "menuEvents"
  | "menuAssignmentPages";

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuAdminPages: true,
    menuCustomers: true,
    menuCustomPages: false,
    menuCoachPages: false,
    menuEvents: true,
    menuAssignmentPages: true,
  });
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      {/* <DashboardMenuItem /> */}
      <SubMenu
        handleToggle={() => handleToggle("menuCustomPages")}
        isOpen={state.menuCustomPages}
        name="User Pages"
        icon={<PagesIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/about"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="What is Uplift"
          leftIcon={<PagesIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/contact"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Contact"
          leftIcon={<PagesIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/privacy"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Privacy"
          leftIcon={<PagesIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/tos"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="TOS"
          leftIcon={<PagesIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/eventcalendar"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Event Calendar"
          leftIcon={<PagesIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/upload"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Share a photo"
          leftIcon={<PagesIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/register"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Register"
          leftIcon={<PagesIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/playerinfo"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Team Info"
          leftIcon={<PagesIcon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuCoachPages")}
        isOpen={state.menuCoachPages}
        name="Coach Pages"
        icon={<SportsIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/lineup"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="LineUp"
          leftIcon={<SportsIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/rotation"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Rotation"
          leftIcon={<ScreenRotationAltIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/teamcommunication"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Roster"
          leftIcon={<SportsIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/devotions"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Devotions"
          leftIcon={<AutoStoriesIcon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuAdminPages")}
        isOpen={state.menuAdminPages}
        name="Admin Pages"
        icon={<PagesIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/adminseasonboard"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Season Configuration"
          leftIcon={<WorkspacesIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/communications"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Mass Communication"
          leftIcon={<EmailIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/adminrotation"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Rotation"
          leftIcon={<ScreenRotationAltIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/playerrandomizer"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Player Randomizer"
          leftIcon={<ScreenRotationAltIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/users"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Users"
          leftIcon={<users.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/teams"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Teams"
          leftIcon={<GroupsIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/players"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Players"
          leftIcon={<SportsBasketballIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/volunteers"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Volunteers"
          leftIcon={<VolunteerActivismIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/events"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Events"
          leftIcon={<CalendarMonthIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/expenses"
          placeholder=""
          state={{ _scrollToTop: true }}
          primaryText="Expenses"
          leftIcon={<MonetizationOnIcon />}
          dense={dense}
        />
      </SubMenu>
    </Box>
  );
};

export default Menu;

import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Markdown from "./modules/components/Markdown";
import Typography from "./modules/components/Typography";
import AppFooter from "./modules/views/AppFooter";
import termsPath from "./modules/views/terms.md";

function Terms() {
  const [terms, setTerms] = React.useState("");
  React.useEffect(() => {
    fetch(termsPath)
      .then((response) => response.text())
      .then((text) => {
        setTerms(text);
      });
  }, []);
  return (
    <React.Fragment>
      <Container>
        <Box sx={{ mt: 7, mb: 12 }}>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Terms
          </Typography>
          <Markdown>{terms}</Markdown>
        </Box>
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default Terms;

import axios from "axios";
import authHeader from "./auth-header";
import Backend from "../utilities/Backend";

const API_URL = `${Backend}/subscriptions/`;
interface Player {
  rank: number;
  firstName: string;
  lastName: string;
  id: number;
  present: boolean;
  playerId: number;
}
const SubscriptionService = new (class SubscriptionService {
  subscribe(email: string) {
    return axios.post(API_URL + email);
  }

  unsubscribe(email: string) {
    return axios.delete(API_URL + email);
  }

  isSubscribed(email: string) {
    return axios.get(API_URL + email).then((response) => {
      return response.data.isSubscribed;
    });
  }
})();

export default SubscriptionService;

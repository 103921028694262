import axios from "axios";
import authHeader from "./auth-header";
import Backend from "../utilities/Backend";

const API_URL = `${Backend}/admin/`;

interface Player {
  rank: number;
  firstName: string;
  lastName: string;
  id: number;
  present: boolean;
  playerId: number;
}
const AdminService = new (class AdminService {
  createNewSeason(useNextYear: Boolean) {
    return axios.post(API_URL + "newseason", { useNextYear }, { headers: authHeader() })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response
    });;
  }

  publishCoaches() {
    return axios.put(API_URL + "publish/coaches", { headers: authHeader() });
  }

  publishPlayers() {
    return axios.put(API_URL + "publish/players", { headers: authHeader() });
  }

  getRandomizerPlayers() {
    return axios.get(API_URL + "getrandomizerplayers", { headers: authHeader() });
  }

  getTeams() {
    return axios.get(API_URL + "getteams", { headers: authHeader() });
  }

  getTeamGroups() {
    return axios.get(API_URL + "getgroups", { headers: authHeader() });
  }

  getPracticeTimes() {
    return axios.get(API_URL + "getpracticetimes", { headers: authHeader() });
  }

  getGameTimes() {
    return axios.get(API_URL + "getgametimes", { headers: authHeader() });
  }

  getCoaches() {
    return axios.get(API_URL + "getcoaches", { headers: authHeader() });
  }

  getHeadCoaches() {
    return axios.get(API_URL + "getheadcoaches", { headers: authHeader() });
  }

  saveTeam(players: Player[]) {
    return axios.post(
      API_URL + "saveteam",
      {
        players,
      },
      { headers: authHeader() },
    );
  }

  getCoachInfo() {
    return axios.get(API_URL + "info", { headers: authHeader() });
  }

  coachInfoUpdate(
    // email: string,
    ageGroups: string[],
    teamName: string,
    preferredPractice: string,
    poloSize: string,
    coachMeeting: string,
    // asstCoachFirstName: string,
    // asstCoachLastName: string,
    // asstCoachPhone: string,
    // asstCoachEmail: string,
    // asstCoachPoloSize: string
  ) {
    return axios
      .post(
        API_URL + "info",
        {
          // email,
          ageGroups,
          teamName,
          preferredPractice,
          poloSize,
          coachMeeting,
          // asstCoachFirstName,
          // asstCoachLastName,
          // asstCoachPhone,
          // asstCoachEmail,
          // asstCoachPoloSize
        },
        {
          headers: authHeader(),
        },
      )
      .then((response) => {
        return response.data;
      });
  }
})();

export default AdminService;

import * as React from "react";
import {
  NumberField,
  TextField,
  DateField,
  useGetList,
  RecordContextProvider,
  useLocaleState,
  useRecordContext,
  Link,
  useReference,
} from "react-admin";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Grid,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { User } from "../../types";

const Aside = () => {
  const record = useRecordContext<User>();
  return (
    <Box width={400} display={{ xs: "none", lg: "block" }}>
      {record && <EventList />}
    </Box>
  );
};

const EventList = () => {
  const record = useRecordContext<User>();

  return (
    <Box ml={2}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            History
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={6} display="flex" gap={1}>
              <AccessTimeIcon fontSize="small" color="disabled" />
              <Box flexGrow={1}>
                <Typography variant="body2">First Seen</Typography>
                <DateField record={record} source="first_seen" />
              </Box>
            </Grid>

            <Grid item xs={6} display="flex" gap={1}>
              <AccessTimeIcon fontSize="small" color="disabled" />
              <Box flexGrow={1}>
                <Typography variant="body2">Last Seen</Typography>
                <DateField record={record} source="last_seen" />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Aside;

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GameList from "./GameList";
import GameCreate from "./GameCreate";
import GameEdit from "./GameEdit";

const resource = {
  list: GameList,
  create: GameCreate,
  edit: GameEdit,
  icon: CalendarMonthIcon,
  recordRepresentation: (record: any) => `${record.title}`,
};

export default resource;

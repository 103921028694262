import {
  Datagrid,
  NumberField,
  DateInput,
  List,
  TextField,
  SearchInput,
} from "react-admin";
import { useMediaQuery, Theme } from "@mui/material";

import MobileGrid from "./MobileGrid";

const PracticeList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm"),
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  return (
    <List perPage={25}>
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            "& .column-groups": {
              md: { display: "none" },
              lg: { display: "table-cell" },
            },
          }}
        >
          <TextField source="day" />
          <NumberField source="time" />
        </Datagrid>
      )}
    </List>
  );
};

export default PracticeList;

import axios from "axios";

import Backend from "../utilities/Backend";

const API_URL = `${Backend}/`;
const ContactService = new (class ContactService {
  contact(email: string, question: string) {
    return axios.post(API_URL + "contact", {
      email,
      question,
    });
  }

  coding(email: string, interest: string) {
    return axios.post(API_URL + "coding", {
      email,
      interest,
    });
  }
})();

export default ContactService;

import * as React from "react";
import {
  Create,
  DateInput,
  SimpleForm,
  TextInput,
  useTranslate,
  PasswordInput,
  BooleanInput,
  email,
} from "react-admin";
import { Box, Typography } from "@mui/material";

export const validateForm = (
  values: Record<string, any>,
): Record<string, any> => {
  const errors = {} as any;
  if (!values.firstName) {
    errors.firstName = "ra.validation.required";
  }
  if (!values.lastName) {
    errors.lastName = "ra.validation.required";
  }
  if (!values.email) {
    errors.email = "ra.validation.required";
  } else {
    const error = email()(values.email);
    if (error) {
      errors.email = error;
    }
  }
  return errors;
};

const VisitorCreate = () => (
  <Create redirect="list">
    <SimpleForm
      sx={{ maxWidth: 500 }}
      // Here for the GQL provider
      defaultValues={{
        birthday: new Date(),
        first_seen: new Date(),
        last_seen: new Date(),
        has_ordered: false,
        latest_purchase: new Date(),
        has_newsletter: false,
        groups: [],
        nb_commands: 0,
        total_spent: 0,
      }}
      validate={validateForm}
    >
      <SectionTitle label="Identity" />
      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="firstName" isRequired fullWidth />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="lastName" isRequired fullWidth />
        </Box>
      </Box>
      <TextInput type="email" source="email" isRequired fullWidth />
      <Separator />
      <SectionTitle label="Address" />
      <TextInput source="address" multiline fullWidth helperText={false} />
      <TextInput source="address2" multiline fullWidth helperText={false} />
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="city" fullWidth helperText={false} />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="state" fullWidth helperText={false} />
        </Box>
        <Box flex={2}>
          <TextInput source="zip" fullWidth helperText={false} />
        </Box>
      </Box>
      <Separator />
      <SectionTitle label="Password" />
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="password"
            fullWidth
            disabled
            defaultValue={"1qaz!QAZ"}
          />
        </Box>
      </Box>
    </SimpleForm>
  </Create>
);

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

export default VisitorCreate;

import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import SubscriptionService from "../services/subscription.service";

const Unsubscribe = () => {
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const Unsubscribe = async () => {
    const email = searchParams.get("email");
    if (email) {
      await SubscriptionService.unsubscribe(email);
      setUnsubscribed(true);
    }
  };
  if (unsubscribed) {
    return (
      <Box component="section" bgcolor="background">
        <Container
          sx={{
            mt: 10,
            mb: 15,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" component="h2" sx={{ mb: 4 }}>
            Sorry to see you go! You have been removed from the mailing list
          </Typography>
        </Container>
      </Box>
    );
  }
  return (
    <Box component="section" bgcolor="background">
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" component="h2" sx={{ mb: 4 }}>
          Unsubscribe from Uplift Newsletter
        </Typography>
        <Button size="large" variant="contained" onClick={Unsubscribe}>
          Unsubscribe
        </Button>
      </Container>
    </Box>
  );
};

export default Unsubscribe;

import * as React from "react";
import {
  DateTimeInput,
  Edit,
  TextInput,
  SimpleForm,
  SelectInput,
  FileInput,
  FileField,
  NumberInput,
  BooleanInput,
  TextField,
} from "react-admin";
import { Grid, Box, Typography } from "@mui/material";

import { validateForm } from "./ExpenseCreate";

const ExpenseEdit = () => {
  return (
    <Edit>
      <SimpleForm
        sx={{ maxWidth: 500 }}
        // Here for the GQL provider
        defaultValues={{
          date: new Date(),
          title: "",
          description: "",
          vendor: "",
          category: "",
          actualAmount: 0,
          budgetedAmount: 0,
        }}
        validate={validateForm}
      >
        <Typography variant="h6" gutterBottom>
          Expenses
        </Typography>
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <DateTimeInput
              source="date"
              fullWidth
              helperText={false}
              // value={startDate}
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <BooleanInput source="reimbursed" />
          </Box>
        </Box>
        <TextInput type="text" source="title" isRequired fullWidth />
        <TextInput type="text" source="description" fullWidth />
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput type="text" source="vendor" fullWidth />
          </Box>

          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <SelectInput
              source="category"
              fullWidth
              choices={[
                { id: "uniforms", name: "Uniforms" },
                { id: "equipment", name: "Equipment" },
                { id: "supplies", name: "Supplies" },
                { id: "prizes", name: "Prizes" },
              ]}
            />
          </Box>
        </Box>
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <NumberInput source="actualAmount" fullWidth helperText={false} />
          </Box>

          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <NumberInput source="budgetedAmount" fullWidth helperText={false} />
          </Box>
        </Box>
        <FileInput source="receipt" fullWidth>
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export default ExpenseEdit;

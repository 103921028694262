import { useState } from "react";
import { Button } from "@mui/material";
import EventBus from "../utilities/EventBus";
import PreviewIcon from "@mui/icons-material/Preview";
export const CoachToggle = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (_: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    EventBus.dispatch("coachView");
  };

  return (
    <>
      <Button onClick={handleChange}>
        <PreviewIcon />
      </Button>
    </>
  );
};

const ucFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

import * as React from "react";
import { Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Typography from "./modules/components/Typography";
import AppFooter from "./modules/views/AppFooter";
import AppForm from "./modules/views/AppForm";
import FormButton from "./modules/form/FormButton";
import ContactService from "../services/contact.service";

const validationSchema = () => {
  return Yup.object({
    email: Yup.string()
      .email("This is not a valid email.")
      .required("Email cannot be blank"),
    interest: Yup.string().required("Question cannot be blank"),
  });
};

const CodingInterest = () => {
  const [redirect, setRedirect] = React.useState("");
  const [sent, setSent] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      interest: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await ContactService.coding(values.email, values.interest).then(
        (response) => {
          setSent(true);
          setTimeout(() => {
            setRedirect("/");
          }, 3000);
        },
        (error) => {
          if (error.response.status === 409) {
            setSent(false);
          }
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        },
      );
    },
  });

  if (redirect) {
    return <Navigate to={redirect} />;
  }
  return (
    <React.Fragment>
      {!sent ? (
        <AppForm>
          <React.Fragment>
            <Typography
              variant="h3"
              gutterBottom
              marked="center"
              align="center"
            >
              Coding Interest
            </Typography>
          </React.Fragment>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Box sx={{ mt: 6 }}>
              <TextField
                autoComplete="email"
                disabled={sent}
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                required
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                autoComplete="interest"
                disabled={sent}
                fullWidth
                label="What are you interested in?"
                margin="normal"
                name="interest"
                required
                rows={5}
                multiline
                id="interest"
                value={formik.values.interest}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.interest && Boolean(formik.errors.interest)
                }
                helperText={formik.touched.interest && formik.errors.interest}
              />
              <FormButton
                sx={{ mt: 3, mb: 2 }}
                disabled={sent}
                color="secondary"
                fullWidth
              >
                Submit
              </FormButton>
            </Box>
          </form>
        </AppForm>
      ) : (
        <Box>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Thanks!
          </Typography>
          <Typography variant="h6" gutterBottom marked="center" align="center">
            I'll reach out soon!
          </Typography>
        </Box>
      )}
      <AppFooter />
    </React.Fragment>
  );
};

export default CodingInterest;

import axios from "axios";
import authHeader from "./auth-header";
import Backend from "../utilities/Backend";

const API_URL = `${Backend}/player/`;
const PlayerService = new (class PlayerService {
  register(
    category: string,
    firstName: string,
    lastName: string,
    gender: string,
    age: number,
    grade: number,
    uniformSize: string,
    yearsExperience: number,
    nickname: string,
    height: number,
  ) {
    return axios
      .post(
        API_URL + "register",
        {
          //Transform into 'C' or 'B'
          category: Array.from(category)[0].toUpperCase(),
          firstName,
          lastName,
          gender,
          age,
          grade,
          uniformSize,
          yearsExperience,
          nickname,
          height,
        },
        {
          headers: authHeader(),
        },
      )
      .then((response) => {
        return response.data;
      });
  }

  upload(formData: FormData) {
    return axios
      .post(API_URL + "upload", formData, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  removePhoto(playerId: string) {
    return axios
      .post(
        API_URL + "removephoto",
        { playerId },
        {
          headers: authHeader(),
        },
      )
      .then((response) => {
        return response.data;
      });
  }
})();

export default PlayerService;

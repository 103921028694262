import React, { useState, useEffect } from "react";

import { Navigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  FormGroup,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useFormik } from "formik";
import { useNotify } from "react-admin";
import * as Yup from "yup";
import Divider from "@mui/material/Divider";
import Typography from "./modules/components/Typography";
import AppFooter from "./modules/views/AppFooter";
import AppForm from "./modules/views/AppForm";
import FormButton from "./modules/form/FormButton";
import FormControl from "@mui/material/FormControl";
import VolunteerService from "../services/volunteer.service";
import AuthService from "../services/auth.service";

// const validationSchema = () => {
//   return Yup.object({
//     poloSize: Yup.string().required("Polo Size cannot be blank"),
//   });
// };
const emptyConstructor: string[] = [];
var initialValues = {
  ageGroups: emptyConstructor,
  teamCount: 0,
  poloSize: "",
  teamNames: emptyConstructor,
  practiceTime: "",
  preferredPractice: "",
  coachMeeting: "",
  volunteerAvailability: emptyConstructor,
  volunteerJobs: emptyConstructor,
  refFrequency: "-1",
  asstFollowId: "",
};

const VolunteerSignUp = () => {
  const [redirect, setRedirect] = useState("");
  const [sent, setSent] = useState(false);
  const [isCoach, setIsCoach] = useState(false);
  const [isReferee, setIsReferee] = useState(false);
  const [isVolunteer, setIsVolunteer] = useState(false);
  const [isAsstCoach, setIsAsstCoach] = useState(false);
  const [volunteerInfo, setVolunteerInfo] = useState(initialValues);
  const [fetchedData, setFetchedData] = useState(false);
  const [needsPolo, setNeedsPolo] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [teamCount, setTeamCount] = useState(0);
  const location = useLocation().pathname;
  const notify = useNotify();

  const fetchData = async () => {
    await VolunteerService.getInfo().then((response) => {
      const volunteer = response.data.volunteer;
      if (volunteer) {
        let teamNames: any[] = [];
        if (volunteer.teamIds.length > 0) {
          volunteer.teamIds.forEach((team: any) => {
            teamNames.push(team.name);
          });
        }
        initialValues.ageGroups = volunteer.ageGroups;
        initialValues.teamNames = teamNames;
        initialValues.practiceTime =
          volunteer.teamIds.length > 0 ? volunteer.teamIds[0].practiceTime : "";
        initialValues.preferredPractice = volunteer.practiceTimePreferences;
        initialValues.poloSize = volunteer.poloSize;
        initialValues.coachMeeting = volunteer.coachMeeting;
        initialValues.volunteerJobs = volunteer.volunteerJobs;
        initialValues.volunteerAvailability = volunteer.volunteerAvailability;
        initialValues.refFrequency = volunteer.refFrequency.toString();
        initialValues.asstFollowId = volunteer.asstFollowId;
        initialValues.teamCount = volunteer.teamIds.length;
        setIsAsstCoach(volunteer.isAsstCoach);
        setIsReferee(volunteer.isReferee);
        setIsCoach(volunteer.isCoach);
        setIsVolunteer(volunteer.isVolunteer);
        setTeamCount(volunteer.teamIds.length);
      }
      setVolunteerInfo(initialValues);
      setFetchedData(true);
    });
  };

  useEffect(() => {
    if (location === "/volunteersignup") {
      const currentUser = AuthService.getAuthenticated();

      if (!currentUser) setRedirect("/");
      if (!fetchedData) fetchData();
    }
  }, [fetchedData]);

  const formik = useFormik({
    initialValues: volunteerInfo,
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSent(true);
      await VolunteerService.signUp(
        isCoach,
        isAsstCoach,
        isReferee,
        isVolunteer,
        values.ageGroups,
        values.teamNames,
        values.teamCount,
        values.preferredPractice,
        values.poloSize,
        values.coachMeeting,
        values.volunteerAvailability,
        values.volunteerJobs,
        values.asstFollowId,
        values.refFrequency as unknown as number,
      ).then(
        //@ts-ignore
        (response) => {
          setSuccessful(true);
          setTimeout(() => {
            setRedirect("/");
          }, 3000);
        },
        //@ts-ignore
        (error) => {
          setSent(false);
          const message = error.response.data;
          notify(
            typeof message === "string"
              ? message
              : typeof message === "undefined" || !message
                ? "ra.auth.sign_up_error"
                : message,
            {
              type: "error",
              messageArgs: {
                _:
                  typeof message === "string"
                    ? message
                    : error && error.message
                      ? error.message
                      : undefined,
              },
            },
          );
        },
      );
    },
  });

  if (redirect) {
    return <Navigate to={redirect} />;
  } else if (successful) {
    return (
      <Box>
        <Typography variant="h3" gutterBottom marked="none" align="center">
          Info Received!
        </Typography>
        <br />
        <Typography variant="h5" gutterBottom marked="none" align="center">
          You're the best!
        </Typography>
      </Box>
    );
  } else {
    return (
      <React.Fragment>
        <AppForm>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Volunteer Sign Up
          </Typography>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Box sx={{ mt: 6 }}>
              <FormControl fullWidth>
                <FormLabel id="volunteerCategories">
                  Volunteer Categories
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    sx={{ display: isAsstCoach ? "none" : "block" }}
                    control={
                      <Checkbox
                        checked={isCoach}
                        onChange={() => setIsCoach(!isCoach)}
                        name="volunteerCategories"
                      />
                    }
                    label="Coach"
                    value="coach"
                  />
                  <FormControlLabel
                    sx={{ display: isCoach ? "none" : "block" }}
                    control={
                      <Checkbox
                        checked={isAsstCoach}
                        onChange={() => setIsAsstCoach(!isAsstCoach)}
                        name="volunteerCategories"
                      />
                    }
                    label="Assistant Coach"
                    value="asstCoach"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isReferee}
                        onChange={() => setIsReferee(!isReferee)}
                        name="volunteerCategories"
                      />
                    }
                    label="Referee"
                    value="referee"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isVolunteer}
                        onChange={() => setIsVolunteer(!isVolunteer)}
                        name="volunteerCategories"
                      />
                    }
                    label="General Volunteer"
                    value="volunteer"
                  />
                </FormGroup>
              </FormControl>
              <Divider sx={{ marginBottom: "5px" }} />
              {(isCoach || isAsstCoach || isVolunteer || isReferee) && (
                <>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setNeedsPolo(!needsPolo)}
                      name="needsPolo"
                    />
                  }
                  label="The polo's aren't changing this year. If you are new or need a new one, please check here and give your size"
                />
                  {needsPolo &&
                    <FormControl fullWidth>
                      <InputLabel id="poloSize">
                        Polo Size (In men's size)
                      </InputLabel>
                      <Select
                        aria-labelledby="poloSize"
                        name="poloSize"
                        onChange={formik.handleChange}
                        value={formik.values.poloSize}
                      >
                        <MenuItem key="s" value="S">
                          S
                        </MenuItem>
                        <MenuItem key="M" value="M">
                          M
                        </MenuItem>
                        <MenuItem key="L" value="L">
                          L
                        </MenuItem>
                        <MenuItem key="XL" value="XL">
                          XL
                        </MenuItem>
                        <MenuItem key="XXL" value="XXL">
                          XXL
                        </MenuItem>
                        <MenuItem key="3XL" value="3XL">
                          3XL
                        </MenuItem>
                        <MenuItem key="4XL" value="4XL">
                          4XL
                        </MenuItem>
                        <MenuItem key="5XL" value="5XL">
                          5XL
                        </MenuItem>
                      </Select>
                    </FormControl>
                  }
                </>
              )}
              {isVolunteer && (
                <>
                  <FormControl fullWidth>
                    <FormLabel id="volunteerAvailability">
                      Availability
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.volunteerAvailability.includes(
                              "practices",
                            )}
                            onChange={formik.handleChange}
                            name="volunteerAvailability"
                          />
                        }
                        label="Practice Nights"
                        value="practices"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.volunteerAvailability.includes(
                              "games",
                            )}
                            onChange={formik.handleChange}
                            name="volunteerAvailability"
                          />
                        }
                        label="Game Days"
                        value="games"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.volunteerAvailability.includes(
                              "camps",
                            )}
                            onChange={formik.handleChange}
                            name="volunteerAvailability"
                          />
                        }
                        label="Coach Camps"
                        value="camps"
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormLabel id="volunteerJobs">Jobs</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.volunteerJobs.includes(
                              "frontdesk",
                            )}
                            onChange={formik.handleChange}
                            name="volunteerJobs"
                          />
                        }
                        label="Front Desk"
                        value="frontdesk"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.volunteerJobs.includes(
                              "opening",
                            )}
                            onChange={formik.handleChange}
                            name="volunteerJobs"
                          />
                        }
                        label="Opening/Locking Up"
                        value="opening"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.volunteerJobs.includes(
                              "scorekeeping",
                            )}
                            onChange={formik.handleChange}
                            name="volunteerJobs"
                          />
                        }
                        label="Scorekeeping"
                        value="scorekeeping"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.volunteerJobs.includes(
                              "spotlights",
                            )}
                            onChange={formik.handleChange}
                            name="volunteerJobs"
                          />
                        }
                        label="SpotLights"
                        value="spotlights"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.volunteerJobs.includes(
                              "announcing",
                            )}
                            onChange={formik.handleChange}
                            name="volunteerJobs"
                          />
                        }
                        label="Announcing"
                        value="announcing"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.volunteerJobs.includes(
                              "all",
                            )}
                            onChange={formik.handleChange}
                            name="volunteerJobs"
                          />
                        }
                        label="Whatever is needed"
                        value="all"
                      />
                    </FormGroup>
                  </FormControl>
                </>
              )}
              {isCoach && !isAsstCoach && (
                <>
                  <TextField
                    fullWidth
                    disabled={sent}
                    required
                    name="teamCount"
                    label="How many teams"
                    type="number"
                    margin="normal"
                    value={formik.values.teamCount}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setTeamCount(Number(e.target.value));
                    }}
                  />
                  <FormControl fullWidth>
                    <FormLabel id="ageGroups">Age Group(s)</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.ageGroups.includes("Boys12")}
                            onChange={formik.handleChange}
                            name="ageGroups"
                          />
                        }
                        label="Boys 1-2"
                        value="Boys12"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.ageGroups.includes("Boys34")}
                            onChange={formik.handleChange}
                            name="ageGroups"
                          />
                        }
                        label="Boys 3-4"
                        value="Boys34"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.ageGroups.includes("Boys56")}
                            onChange={formik.handleChange}
                            name="ageGroups"
                          />
                        }
                        label="Boys 5-6"
                        value="Boys56"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.ageGroups.includes(
                              "Girls13",
                            )}
                            onChange={formik.handleChange}
                            name="ageGroups"
                          />
                        }
                        label="Girls 1-3"
                        value="Girls13"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.ageGroups.includes(
                              "Girls46",
                            )}
                            onChange={formik.handleChange}
                            name="ageGroups"
                          />
                        }
                        label="Girls 4-6"
                        value="Girls46"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.ageGroups.includes(
                              "Cheerleading",
                            )}
                            onChange={formik.handleChange}
                            name="ageGroups"
                          />
                        }
                        label="Cheerleading"
                        value="Cheerleading"
                      />
                    </FormGroup>
                  </FormControl>

                  {(() => {
                    const arr = [];
                    for (let i = 0; i < teamCount; i++) {
                      arr.push(
                        <TextField
                          key={`teamNames[${i}]`}
                          fullWidth
                          disabled={sent}
                          name={`teamNames[${i}]`}
                          autoComplete="teamName"
                          label="Fun team name"
                          type="text"
                          margin="normal"
                          id={`teamNames[${i}]`}
                          value={formik.values.teamNames[i]}
                          onChange={formik.handleChange}
                        />,
                      );
                    }
                    return arr;
                  })()}
                  <TextField
                    fullWidth
                    disabled={sent}
                    name="preferredPractice"
                    autoComplete="preferredPractice"
                    label="Practice night/time preferences?"
                    type="text"
                    margin="normal"
                    id="preferredPractice"
                    value={formik.values.preferredPractice}
                    onChange={formik.handleChange}
                  />
                  <FormControl fullWidth>
                    <FormLabel id="coachMeeting">
                      Pre-season Coach's Meeting
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="coaches meeting"
                      name="coachMeeting"
                    >
                      <FormControlLabel
                        value="Oct23"
                        control={
                          <Radio
                            checked={formik.values.coachMeeting === "Oct23"}
                            onChange={formik.handleChange}
                            name="coachMeeting"
                          />
                        }
                        label="Oct 23 @ 5:30PM"
                      />
                      <FormControlLabel
                        value="Oct27"
                        control={
                          <Radio
                            checked={formik.values.coachMeeting === "Oct27"}
                            onChange={formik.handleChange}
                            name="coachMeeting"
                          />
                        }
                        label="Oct 27 after service"
                      />
                      <FormControlLabel
                        value="none"
                        control={
                          <Radio
                            checked={formik.values.coachMeeting === "none"}
                            onChange={formik.handleChange}
                            name="coachMeeting"
                          />
                        }
                        label="Can't make either"
                      />
                    </RadioGroup>
                  </FormControl>
                </>
              )}
              {isAsstCoach && !isCoach && (
                <>
                  <FormControl fullWidth>
                    <FormLabel id="coachMeeting">
                      Pre-season Coach's Meeting (Optional but strongly
                      encouraged)
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="coaches meeting"
                      name="coachMeeting"
                    >
                      <FormControlLabel
                        value="Oct23"
                        control={
                          <Radio
                            checked={formik.values.coachMeeting === "Oct23"}
                            onChange={formik.handleChange}
                            name="coachMeeting"
                          />
                        }
                        label="Oct 23"
                      />
                      <FormControlLabel
                        value="Oct27"
                        control={
                          <Radio
                            checked={formik.values.coachMeeting === "Oct27"}
                            onChange={formik.handleChange}
                            name="coachMeeting"
                          />
                        }
                        label="Oct 27"
                      />
                      <FormControlLabel
                        value="none"
                        control={
                          <Radio
                            checked={formik.values.coachMeeting === "none"}
                            onChange={formik.handleChange}
                            name="coachMeeting"
                          />
                        }
                        label="Can't make either"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Divider sx={{ marginBottom: "5px" }} />
                  <Typography>
                    If you would like to be linked with a specific head coach or
                    you want to be linked to the team that your child gets
                    assigned to, enter the coach's/child's name
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={sent}
                    name="asstFollowId"
                    autoComplete="asstFollowId"
                    label="Name of coach or child"
                    type="text"
                    margin="normal"
                    id="asstFollowId"
                    value={formik.values.asstFollowId}
                    onChange={formik.handleChange}
                  />
                </>
              )}
              {isReferee && (
                <FormControl fullWidth>
                  <FormLabel id="refFrequency">Refereeing Frequency</FormLabel>
                  <RadioGroup aria-labelledby="Frequency" name="frequency">
                    <FormControlLabel
                      value={2}
                      control={
                        <Radio
                          checked={formik.values.refFrequency === "2"}
                          onChange={formik.handleChange}
                          name="refFrequency"
                        />
                      }
                      label="Available all day on Saturdays"
                    />
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checked={formik.values.refFrequency === "1"}
                          onChange={formik.handleChange}
                          name="refFrequency"
                        />
                      }
                      label="A couple games each Saturday"
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          checked={formik.values.refFrequency === "0"}
                          onChange={formik.handleChange}
                          name="refFrequency"
                        />
                      }
                      label="Prefer only when needed"
                    />
                  </RadioGroup>
                </FormControl>
              )}

              <FormButton
                sx={{ mt: 3, mb: 2 }}
                disabled={sent}
                color="secondary"
                fullWidth
              >
                {sent ? "In progress…" : "Volunteer"}
              </FormButton>
            </Box>
            {/* <pre>{JSON.stringify(formik.values, null, 2)}</pre> */}
          </form>
        </AppForm>
        <AppFooter />
      </React.Fragment>
    );
  }
};

export default VolunteerSignUp;

/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import Box from "@mui/material/Box";
import Typography from "../components/Typography";

// Material Kit 2 React examples
import HorizontalTeamCard from "../components/CommitteeCard";

// Images
import chris from "../../../images/chris_image.png";
import team2 from "../../../images/uplift-logo.png";
import team3 from "../../../images/uplift-logo.png";
import team4 from "../../../images/uplift-logo.png";

function Team() {
  return (
    <Box
      component="section"
      sx={{
        bgcolor: "gray",
        position: "relative",
      }}
      // variant="gradient"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <Typography variant="h3" color="white">
              The Uplift Committee
            </Typography>
            <Typography variant="body2" color="white" opacity={0.8}>
              Putting our heads together to have the best possible Uplift season
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Box mb={1}>
              <HorizontalTeamCard
                image={chris}
                name="Chris Schwendeman"
                position={{ color: "info", label: "Director" }}
                description="Schedule making, uniform ordering, website building. I have my hands in everything"
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Jillian Schwendeman"
                position={{ color: "info", label: "Cheerleading Director" }}
                description="Team sorting, cheer creating, uniform designing. The voice of all things cheer"
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Jon Reaves"
                position={{ color: "info", label: "Pastor" }}
                description="A wealth of knowledge and devotion planner"
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="The Fullers"
                position={{ color: "info", label: "Dynamic Duo" }}
                description="Generally awesome. Helped in any aspect that they were needed"
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Eddie Heath"
                position={{ color: "info", label: "Coach Extraordinaire" }}
                description="Crucial to promoting Uplift and amazing coach taking on two teams this year"
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Ron Turman"
                position={{ color: "info", label: "The Wild Card" }}
                description="Idea man, substitute coach, referee "
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Team;

import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Markdown from "./modules/components/Markdown";
import Typography from "./modules/components/Typography";
import AppFooter from "./modules/views/AppFooter";
import week3Path from "./devotions/week3.md";
import week4Path from "./devotions/week4.md";
import week5Path from "./devotions/week5.md";
import week6Path from "./devotions/week6.md";
import week7Path from "./devotions/week7.md";
import week8Path from "./devotions/week8.md";
import week9Path from "./devotions/week9.md";
import week10Path from "./devotions/week10.md";

function Devotions() {
  const [text, setText] = React.useState("");
  const [weekNumber, setWeekNumber] = React.useState(0);

  const weekPaths = [
    week3Path,
    week4Path,
    week5Path,
    week6Path,
    week7Path,
    week8Path,
    week9Path,
    week10Path,
  ];
  React.useEffect(() => {
    fetch(weekPaths[weekNumber])
      .then((response) => response.text())
      .then((text) => {
        setText(text);
      });
  }, [weekNumber]);

  const onWeekChange = (event: any) => {
    setWeekNumber(Number(event.target.value));
  };
  return (
    <React.Fragment>
      <Box sx={{ paddingLeft: "9px", paddingTop: "8px", width: "200px" }}>
        <FormControl fullWidth>
          <InputLabel id="week-selector-label">Week</InputLabel>
          <Select
            labelId="week-selector-label"
            id="week-selector"
            defaultValue={0}
            label="Week"
            onChange={onWeekChange}
          >
            <MenuItem value={0}>Week 3 (Jan 8-11)</MenuItem>
            <MenuItem value={1}>Week 4 (Jan 15-18)</MenuItem>
            <MenuItem value={2}>Week 5 (Jan 22-25)</MenuItem>
            <MenuItem value={3}>Week 6 (Jan 29 - Feb 1)</MenuItem>
            <MenuItem value={4}>Week 7 (Feb 5-8)</MenuItem>
            <MenuItem value={5}>Week 8 (Feb 12-15)</MenuItem>
            <MenuItem value={6}>Week 9 (Feb 19-22)</MenuItem>
            <MenuItem value={7}>Week 10 (Feb 26-22)</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Container>
        <Box sx={{ mt: 7, mb: 12 }}>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Devotion
          </Typography>
          <Markdown>{text}</Markdown>
        </Box>
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default Devotions;

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TeamList from "./TeamList";
import TeamCreate from "./TeamCreate";
import TeamEdit from "./TeamEdit";

const resource = {
  list: TeamList,
  create: TeamCreate,
  edit: TeamEdit,
  icon: CalendarMonthIcon,
  recordRepresentation: (record: any) => `${record.title}`,
};

export default resource;

import * as React from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Title } from "react-admin";

import LinkToRelatedCustomers from "./LinkToRelatedCustomers";
import segments from "./data";

const Segments = () => {
  return (
    <Card sx={{ mt: 2 }}>
      <Title title="Segments" />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {segments.map((segment) => (
            <TableRow key={segment.id}>
              <TableCell>{segment.name}</TableCell>
              <TableCell>
                <LinkToRelatedCustomers segment={segment.id} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default Segments;

import EmailIcon from "@mui/icons-material/Email";
import CommunicationList from "./CommunicationList";
import CommunicationCreate from "./CommunicationCreate";
import CommunicationShow from "./CommunicationShow";

const resource = {
  list: CommunicationList,
  create: CommunicationCreate,
  show: CommunicationShow,
  icon: EmailIcon,
  recordRepresentation: (record: any) => `${record.title}`,
};

export default resource;

/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UserService from "../../../services/user.service";
import SubscriptionService from "../../../services/subscription.service";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
//@ts-ignore
function PlatformSettings({ data }) {
  const [textPreference, setTextPreference] = useState(data.text);
  const [textConsent, setTextConsent] = useState(data.textConsent);
  const [emailPreference, setEmailPreference] = useState(data.email);
  const [initialized, setInitialized] = useState(false);
  const [practiceReminder, setPracticeReminder] = useState(data.practice);
  const [gameReminder, setGameReminder] = useState(data.game);

  const [newLaunches, setNewLaunches] = useState(false);
  const [productUpdate, setProductUpdate] = useState(true);
  const [newsletter, setNewsletter] = useState(false);

  const TextPreference = async (setting: boolean) => {
    setTextPreference(setting);
    if (!setting && !emailPreference) {
      setEmailPreference(true);
      await UserService.userPreferenceUpdate(setting, true, textConsent);
    } else {
      await UserService.userPreferenceUpdate(setting, emailPreference, textConsent);
    }
  };

  const EmailPreference = async (setting: boolean) => {
    setEmailPreference(setting);
    if (!setting && !textPreference) {
      setTextPreference(true);
      await UserService.userPreferenceUpdate(true, setting, textConsent);
    } else {
      await UserService.userPreferenceUpdate(textPreference, setting, textConsent);
    }
  };

  const PracticeReminder = async (setting: boolean) => {
    setPracticeReminder(setting);
    await UserService.userReminderUpdate(setting, gameReminder);
  };

  const GameReminder = async (setting: boolean) => {
    setGameReminder(setting);
    await UserService.userReminderUpdate(practiceReminder, setting);
  };

  const Newsletter = async (setting: boolean) => {
    setNewsletter(setting);
    if (setting) {
      await SubscriptionService.subscribe(data.emailAddress);
    } else {
      await SubscriptionService.unsubscribe(data.emailAddress);
    }
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <Box p={2}>
        <Typography variant="h6" fontWeight="medium" textTransform="capitalize">
          Contact Preferences
        </Typography>
      </Box>
      <Box pb={2} px={2} lineHeight={1.25}>
        <Typography
          variant="caption"
          fontWeight="bold"
          color="text"
          textTransform="uppercase"
        >
          Methods
        </Typography>
        <Box display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <Box mt={0.5}>
            <Switch
              disabled={!textConsent}
              checked={textPreference}
              onChange={() => TextPreference(!textPreference)}
            />
          </Box>
          <Box width="80%" ml={0.5}>
            <Typography variant="button" fontWeight="regular" color="text">
              Receive Texts
            </Typography>
          </Box>
            { !textConsent && 
            <FormGroup>
              <FormControlLabel control={<Checkbox onChange={(e: any) => setTextConsent(!textConsent)} name="textConsent" />}
                label={
                  <>
                    <Typography variant="caption" gutterBottom> {"By checking this box, you agree to receive text messages from both coaches and admins of Uplift Basketball related to the league. Message & data rates may apply. Message frequency varies. Reply STOP to cancel or opt out on the profile page. View our "}
                      <Link href="https://upliftbasketball.com/tos">Terms of Service</Link>
                      {" and "}
                      <Link href="https://upliftbasketball.com/privacy"> Privacy Policy</Link>
                    </Typography>
                  </>
                }
              />
            </FormGroup>
          }
        </Box>
        <Box display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <Box mt={0.5}>
            <Switch
              disabled={!textConsent}
              checked={emailPreference}
              onChange={() => EmailPreference(!emailPreference)}
            />
          </Box>
          <Box width="80%" ml={0.5}>
            <Typography variant="button" fontWeight="regular" color="text">
              Receive Emails
            </Typography>
          </Box>
        </Box>
        <Typography variant="caption" fontWeight="regular" color="text">
          At least one needs to remain on. We encourage you to keep both on.
        </Typography>
        <Box mt={3}>
          <Typography
            variant="caption"
            fontWeight="bold"
            color="text"
            textTransform="uppercase"
          >
            Automated Reminders
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <Box mt={0.5}>
            <Switch
              checked={practiceReminder}
              onChange={() => PracticeReminder(!practiceReminder)}
            />
          </Box>
          <Box width="80%" ml={0.5}>
            <Typography variant="button" fontWeight="regular" color="text">
              Send practice reminders
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <Box mt={0.5}>
            <Switch
              checked={gameReminder}
              onChange={() => GameReminder(!gameReminder)}
            />
          </Box>
          <Box width="80%" ml={0.5}>
            <Typography variant="button" fontWeight="regular" color="text">
              Send game reminders
            </Typography>
          </Box>
        </Box>
        {/* TODO: Make this work */}
        <Box display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <Box mt={0.5}>
            <Switch
              checked={newsletter}
              onChange={() => Newsletter(!newsletter)}
            />
          </Box>
          <Box width="80%" ml={0.5}>
            <Typography variant="button" fontWeight="regular" color="text">
              Subscribe to newsletter
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

PlatformSettings.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PlatformSettings;

import * as React from "react";
import { Grid, Box, Typography } from "@mui/material";

import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  RichTextField,
} from "react-admin";

const CommunicationShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              Communications
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <DateField source="date" />
              </Box>
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <Typography variant="subtitle2" gutterBottom>
                To:
                <TextField source="to" />
              </Typography>
            </Box>

            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <Typography variant="subtitle2" gutterBottom>
                From:
                <TextField source="from" />
              </Typography>
            </Box>

            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <Typography variant="subtitle2" gutterBottom>
                Subject:
                <TextField source="subject" />
              </Typography>
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <Typography variant="subtitle2" gutterBottom>
                Message:
                <TextField source="message" />
              </Typography>
            </Box>

            <Box mt="1em" />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};

export default CommunicationShow;

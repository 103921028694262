import { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import AuthService from "../services/auth.service";
import AdminService from "../services/admin.service";
import { useNotify } from "react-admin";
import EventBus from "../utilities/EventBus";

//TODO: Need to finish this page once valid test data is available. Goal is to
// have a page where I can select individual coaches, who categories, or everyone
// to send a mass email to. Should also have a toggle for newsletter creation.
// Also the ability to override communication preferences for large emails that
// shouldn't be text
const AdminCommunication = () => {
  const [checked, setChecked] = useState([true, false]);
  const [redirect, setRedirect] = useState("");
  const location = useLocation().pathname;
  const [data, setData] = useState({ teams: [] });
  const notify = useNotify();

  useEffect(() => {
    if (location === "/admincommunication") {
      const currentUser = AuthService.getAuthenticated();
      const isAdmin = AuthService.isAdmin();

      if (!currentUser) {
        setRedirect("/login");
      } else if (!isAdmin) {
        setRedirect("/");
      }
      if (data.teams.length === 0) {
        AdminService.getCoaches().then(
          (response) => {
            setData(response.data);
          },
          (error) => {
            if (error.response && error.response.status === 401) {
              EventBus.dispatch("logout");
              setRedirect("/");
            } else {
              notify(
                typeof error === "string"
                  ? error
                  : typeof error === "undefined" || !error.message
                    ? "ra.auth.sign_in_error"
                    : error.message,
                {
                  type: "error",
                  messageArgs: {
                    _:
                      typeof error === "string"
                        ? error
                        : error && error.message
                          ? "Error retrieving data"
                          : undefined,
                  },
                },
              );
            }
          },
        );
      }
    }
  }, []);

  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([event.target.checked, checked[1]]);
  };

  const handleChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([checked[0], event.target.checked]);
  };

  const children = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      <FormControlLabel
        label="Child 1"
        control={<Checkbox checked={checked[0]} onChange={handleChange2} />}
      />
      <FormControlLabel
        label="Child 2"
        control={<Checkbox checked={checked[1]} onChange={handleChange3} />}
      />
    </Box>
  );

  if (redirect !== "") {
    return <Navigate to={redirect} />;
  }
  return (
    <div>
      <FormControlLabel
        label="Parent"
        control={
          <Checkbox
            checked={checked[0] && checked[1]}
            indeterminate={checked[0] !== checked[1]}
            onChange={handleChange1}
          />
        }
      />
      {children}
    </div>
  );
};

export default AdminCommunication;

/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Material Kit 2 React examples
import CounterCard from "../components/CounterCard";

function Featuring() {
  return (
    <Box component="section" pt={3}>
      <Container>
        {/* <Grid container spacing={3} sx={{ mb: 12 }}>
          <Grid item xs={6} md={4} lg={2}>
            <Box component="img" src={coinbase} alt="coinbase" sx={{width:"100%", opacity:"0.7"}} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <Box component="img" src={nasa} alt="nasa" sx={{width:"100%", opacity:"0.7"}} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <Box component="img" src={netflix} alt="netflix" sx={{width:"100%", opacity:"0.7"}} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <Box component="img" src={pinterest} alt="pinterest" sx={{width:"100%", opacity:"0.7"}} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <Box component="img" src={spotify} alt="spotify" sx={{width:"100%", opacity:"0.7"}} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <Box component="img" src={vodafone} alt="vodafone" sx={{width:"100%", opacity:"0.7"}} />
          </Grid>
        </Grid> */}
        <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={3}>
            <CounterCard
              count={2000}
              separator=","
              suffix="+"
              title="Children"
              description="That have participated"
              color="primary"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CounterCard
              count={15}
              separator=","
              title="Years"
              description="Since the program began"
              color="primary"
            />
          </Grid>
          {/* TODO: Need one more statistic */}
          <Grid item xs={12} md={3}>
            <CounterCard
              count={1000}
              suffix="+"
              title="Games Played"
              description="Games played through past seasons"
              color="primary"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Featuring;

// in src/comments.js
import * as React from "react";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import {
  DateField,
  EditButton,
  TextField,
  RecordContextProvider,
  useListContext,
} from "react-admin";

import { User } from "../../types";

const MobileGrid = () => {
  const { data, isLoading } = useListContext<User>();

  if (isLoading || data.length === 0) {
    return null;
  }
  return (
    <Box margin="0.5em">
      {data.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
          <Card sx={{ margin: "0.5rem 0" }}>
            <CardHeader
              title={record.title}
              subheader={
                <>
                  Start Date: &nbsp;
                  <DateField source="start" />
                  <br />
                  End Date: &nbsp;
                  <DateField source="end" />
                </>
              }
              action={<EditButton />}
            />
            <CardContent sx={{ pt: 0 }}>
              <Typography variant="body2">
                Description :&nbsp;
                <TextField source="desc" />
              </Typography>
            </CardContent>
          </Card>
        </RecordContextProvider>
      ))}
    </Box>
  );
};

export default MobileGrid;

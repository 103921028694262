/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// Material Kit 2 React components
import Box from "@mui/material/Box";
import Typography from "../components/Typography";

import subscriptionService from "../../../services/subscription.service";
import { useFormik } from "formik";
import * as Yup from "yup";

// Images
import Logo from "../../../images/uplift-logo.png";

const validationSchema = () => {
  return Yup.object({
    email: Yup.string()
      .email("This is not a valid email.")
      .required("Email cannot be blank"),
  });
};

function Newsletter() {
  const [subscribed, setSubscribed] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubscribed(true);
      const { email } = values;
      subscriptionService.subscribe(email);
    },
  });

  const handleClose = () => {
    setSubscribed(false);
  };

  return (
    <Box component="section" pt={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid
            item
            sm={12}
            md={6}
            sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}
          >
            <Typography variant="h4">Be the first to see the news</Typography>
            <Typography variant="body2" color="text" mb={3}>
              Subscribe to receive updates about the Uplift season and
              registration opening
            </Typography>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <TextField
                    name="email"
                    type="email"
                    label="Email Here..."
                    fullWidth
                    id="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="info"
                    sx={{ height: "100%" }}
                    type="submit"
                  >
                    Subscribe
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Snackbar
            open={subscribed}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={6000}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Thanks for Subscibing!
            </Alert>
          </Snackbar>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <Box position="relative">
              <Box component="img" src={Logo} alt="logo" width="100%" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Newsletter;

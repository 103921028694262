import * as React from "react";
import {
  DateTimeInput,
  Edit,
  TextInput,
  SimpleForm,
  BooleanInput,
  ReferenceInput,
  NumberInput,
  DateInput,
  ReferenceArrayInput,
} from "react-admin";
import { Grid, Box, Typography } from "@mui/material";

import { validateForm } from "./VolunteerCreate";
//TODO this whole page is messed up
const CoachEdit = () => (
  <Edit>
    <SimpleForm validate={validateForm}>
      <TextInput source="userId.firstName" label="First Name"/>
      <TextInput source="userId.lastName" label="Last Name" />
      <TextInput source="userId.email" label="Email" />
      <TextInput source="userId.phone" label="Phone" />
      <TextInput source="ageGroups" label="" />
      <TextInput source="assignedGroup" label="" />
      <TextInput source="volunteerTasks" label="" />
      <TextInput source="poloSize" />
      <DateInput source="coachMeeting" />
      <DateInput source="year" />
      <DateInput source="ballOrCheer" />
      <DateInput source="practiceTimePreferences" />
      <ReferenceArrayInput source="teamIds" reference="teams">
        <TextInput source="id" />
      </ReferenceArrayInput>
      <BooleanInput source="isAsstCoach" />
      <BooleanInput source="isCoach" />
      <BooleanInput source="isReferee" />
      <BooleanInput source="isVolunteer" />
      <TextInput source="id" />
    </SimpleForm>
  </Edit>
);

export default CoachEdit;

/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

import { useFormik, Formik } from "formik";
import * as Yup from "yup";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import SvgIcon from "@mui/material/SvgIcon";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import {
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  FormGroup,
  TextField,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MuiPhoneNumber from "mui-phone-number";
import phone from "phone";
import Typography from "@mui/material/Typography";
import UserService from "../../../services/user.service";
import CheckIcon from "@mui/icons-material/Check";
//@ts-ignore
function ProfileInfoCard({ title, description, action, shadow, data }) {
  const [editMode, setEditMode] = useState(false);
  const [sent, setSent] = useState(false);

  const phoneRegExp =
    /^(\+[1-9]{1,4}[ \\-]*)(\([0-9]{2,3}\)[ \\-]*)[0-9]{3,4}?[ \\-]*[0-9]{4}?$/;
  const validationSchema = () => {
    return Yup.object({
      firstName: Yup.string().required("First name cannot be blank"),
      lastName: Yup.string().required("Last name cannot be blank"),
      email: Yup.string()
        .email("This is not a valid email.")
        .required("Email cannot be blank"),
      phone: Yup.string()
        .test(
          "len",
          "Valid phone number is required",
          (val: any) => val && phone(val).isValid,
        )
        // .matches(phoneRegExp, 'Phone number is not valid')
        .required("Phone number cannot be blank"),
      address: Yup.string().required("Address is required!"),
      city: Yup.string().required("City is required!"),
      zip: Yup.string()
        .min(5, "Zip should be five digits")
        .max(5, "Zip should be five digits")
        .required("Zip is required!"),
    });
  };

  const handleSubmit = async (formValue: {
    email: string;
    address: string;
    address2: string;
    city: string;
    firstName: string;
    lastName: string;
    state: string;
    phone: string;
    zip: string;
    isMember: boolean;
  }) => {
    setSent(true);

    const formattedPhone = phone(formValue.phone);
    await UserService.userInfoUpdate(
      formValue.firstName,
      formValue.lastName,
      formattedPhone.phoneNumber as string,
      formValue.email,
      formValue.address,
      formValue.address2,
      formValue.city,
      formValue.state,
      formValue.zip,
      formValue.isMember,
    ).then(
      //@ts-ignore
      (response) => {
        setEditMode(false);
        setSent(false);
        data.firstName = formValue.firstName;
        data.lastName = formValue.lastName;
        data.phone = formValue.phone;
        data.address = formValue.address;
        data.address2 = formValue.address2;
        data.city = formValue.city;
        data.state = formValue.state;
        data.zip = formValue.zip;
        data.isMember = formValue.isMember;
        window.location.reload();
      },
      //@ts-ignore
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setSent(false);
      },
    );
  };

  const editToggle = () => {
    setEditMode(!editMode);
  };

  return (
    <Card sx={{ height: "100%", boxShadow: 1, width: "100%" }}>
      {editMode ? (
        <Formik
          initialValues={data}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} noValidate>
              <Box sx={{ mt: 2, mx: 1 }}>
                <Typography
                  variant="h6"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  Edit Profile
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      disabled={sent}
                      fullWidth
                      label="First name"
                      name="firstName"
                      id="firstName"
                      value={props.values.firstName}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.firstName &&
                        Boolean(props.errors.firstName)
                      }
                      helperText={
                        props.touched.firstName && props.errors.firstName
                      }
                      required
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      disabled={sent}
                      autoComplete="family-name"
                      fullWidth
                      label="Last name"
                      name="lastName"
                      required
                      id="lastName"
                      value={props.values.lastName}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.lastName && Boolean(props.errors.lastName)
                      }
                      helperText={
                        props.touched.lastName && props.errors.lastName
                      }
                      size="small"
                    />
                  </Grid>
                </Grid>
                <TextField
                  variant="outlined"
                  autoComplete="email"
                  disabled={true}
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="email"
                  required
                  id="email"
                  value={props.values.email}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={props.touched.email && Boolean(props.errors.email)}
                  helperText={props.touched.email && props.errors.email}
                  size="small"
                />
                <MuiPhoneNumber
                  variant="outlined"
                  defaultCountry={"us"}
                  disabled={sent}
                  fullWidth
                  label="Phone"
                  margin="normal"
                  name="phone"
                  required
                  id="phone"
                  value={props.values.phone}
                  onChange={(e) => props.setFieldValue("phone", e)}
                  onBlur={props.handleBlur}
                  error={props.touched.phone && Boolean(props.errors.phone)}
                  helperText={props.touched.phone && props.errors.phone}
                  size="small"
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      autoComplete="address"
                      disabled={sent}
                      fullWidth
                      label="Address"
                      margin="normal"
                      name="address"
                      required
                      id="address"
                      value={props.values.address}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.address && Boolean(props.errors.address)
                      }
                      helperText={props.touched.address && props.errors.address}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      autoComplete="address2"
                      disabled={sent}
                      fullWidth
                      label="Address 2"
                      margin="normal"
                      name="address2"
                      id="address2"
                      value={props.values.address2}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.address2 && Boolean(props.errors.address2)
                      }
                      helperText={
                        props.touched.address2 && props.errors.address2
                      }
                      size="small"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      disabled={sent}
                      required
                      name="city"
                      autoComplete="city"
                      label="City"
                      margin="normal"
                      id="city"
                      value={props.values.city}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.touched.city && Boolean(props.errors.city)}
                      helperText={props.touched.city && props.errors.city}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2.5}>
                    <FormControl fullWidth margin="normal" variant="outlined">
                      <InputLabel id="state-select-label">State</InputLabel>
                      <Select
                        labelId="state-select-label"
                        id="state"
                        required
                        label="State"
                        value={props.values.state}
                        name="state"
                        onChange={props.handleChange}
                        size="small"
                      >
                        <MenuItem value="AL">AL</MenuItem>
                        <MenuItem value="AK">AK</MenuItem>
                        <MenuItem value="AR">AR</MenuItem>
                        <MenuItem value="AZ">AZ</MenuItem>
                        <MenuItem value="CA">CA</MenuItem>
                        <MenuItem value="CO">CO</MenuItem>
                        <MenuItem value="CT">CT</MenuItem>
                        <MenuItem value="DE">DE</MenuItem>
                        <MenuItem value="DC">DC</MenuItem>
                        <MenuItem value="FL">FL</MenuItem>
                        <MenuItem value="GA">GA</MenuItem>
                        <MenuItem value="HI">HI</MenuItem>
                        <MenuItem value="ID">ID</MenuItem>
                        <MenuItem value="IL">IL</MenuItem>
                        <MenuItem value="IN">IN</MenuItem>
                        <MenuItem value="IA">IA</MenuItem>
                        <MenuItem value="KS">KS</MenuItem>
                        <MenuItem value="KY">KY</MenuItem>
                        <MenuItem value="LA">LA</MenuItem>
                        <MenuItem value="ME">ME</MenuItem>
                        <MenuItem value="MD">MD</MenuItem>
                        <MenuItem value="MA">MA</MenuItem>
                        <MenuItem value="MN">MN</MenuItem>
                        <MenuItem value="MS">MS</MenuItem>
                        <MenuItem value="MO">MO</MenuItem>
                        <MenuItem value="MT">MT</MenuItem>
                        <MenuItem value="NE">NE</MenuItem>
                        <MenuItem value="NV">NV</MenuItem>
                        <MenuItem value="NH">NH</MenuItem>
                        <MenuItem value="NJ">NJ</MenuItem>
                        <MenuItem value="NM">NM</MenuItem>
                        <MenuItem value="NY">NY</MenuItem>
                        <MenuItem value="NC">NC</MenuItem>
                        <MenuItem value="ND">ND</MenuItem>
                        <MenuItem value="OH">OH</MenuItem>
                        <MenuItem value="OK">OK</MenuItem>
                        <MenuItem value="OR">OR</MenuItem>
                        <MenuItem value="PA">PA</MenuItem>
                        <MenuItem value="PR">PR</MenuItem>
                        <MenuItem value="RI">RI</MenuItem>
                        <MenuItem value="SC">SC</MenuItem>
                        <MenuItem value="SD">SD</MenuItem>
                        <MenuItem value="TN">TN</MenuItem>
                        <MenuItem value="TX">TX</MenuItem>
                        <MenuItem value="UT">UT</MenuItem>
                        <MenuItem value="VT">VT</MenuItem>
                        <MenuItem value="VA">VA</MenuItem>
                        <MenuItem value="WA">WA</MenuItem>
                        <MenuItem value="WV">WV</MenuItem>
                        <MenuItem value="WI">WI</MenuItem>
                        <MenuItem value="WY">WY</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3.5}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      disabled={sent}
                      required
                      name="zip"
                      autoComplete="zip"
                      label="Zip"
                      margin="normal"
                      id="zip"
                      value={props.values.zip}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.touched.zip && Boolean(props.errors.zip)}
                      helperText={props.touched.zip && props.errors.zip}
                      size="small"
                    />
                  </Grid>
                </Grid>
                <FormGroup sx={{ display: "block" }}>
                  <FormControlLabel
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    control={<Checkbox checked={props.values.isMember} />}
                    labelPlacement="start"
                    label="Member of Tannehill?"
                    name="isMember"
                  />
                </FormGroup>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      sx={{ mb: 2 }}
                      fullWidth
                      disabled={sent}
                      color="secondary"
                      variant="contained"
                      type="submit"
                    >
                      {sent ? "In progress…" : "Save"}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      sx={{ mb: 2 }}
                      fullWidth
                      disabled={sent}
                      color="secondary"
                      variant="contained"
                      onClick={editToggle}
                    >
                      {sent ? "In progress…" : "Cancel"}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pt={2}
            px={2}
          >
            <Typography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {title}
            </Typography>
            <Typography
              component={Link}
              to={action.route}
              variant="body2"
              color="secondary"
            >
              <Tooltip title={action.tooltip} placement="top">
                <SvgIcon component={EditIcon} onClick={editToggle} />
              </Tooltip>
            </Typography>
          </Box>
          <Box p={2}>
            <Box sx={{ opacity: 0.3 }}>
              <Divider />
            </Box>
            <Box>
              <Box key="Name" display="flex" py={1} pr={2}>
                <Typography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Name: &nbsp;
                </Typography>
                <Typography variant="button" fontWeight="regular" color="text">
                  &nbsp;{data.firstName} {data.lastName}
                </Typography>
              </Box>
              <Box key="Email" display="flex" py={1} pr={2}>
                <Typography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Email: &nbsp;
                </Typography>
                <Typography variant="button" fontWeight="regular" color="text">
                  &nbsp;{data.email}
                </Typography>
              </Box>
              <Box key="Phone" display="flex" py={1} pr={2}>
                <Typography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Mobile: &nbsp;
                </Typography>
                <Typography variant="button" fontWeight="regular" color="text">
                  &nbsp;{data.phone}
                </Typography>
              </Box>
              <Box key="Address" display="flex" py={1} pr={2}>
                <Typography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Address: &nbsp;
                </Typography>
                <Typography variant="button" fontWeight="regular" color="text">
                  &nbsp;{data.address} <br />
                  {data.address2 && (
                    <>
                      &nbsp;{data.address2}
                      <br />
                    </>
                  )}
                  &nbsp;{data.city} {data.state} {data.zip}
                </Typography>
              </Box>
              <Box key="isMember" display="flex" py={1} pr={2}>
                <Typography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Member of Tannehill: &nbsp;
                </Typography>
                <Typography variant="button" fontWeight="regular" color="text">
                  {data.isMember ? "Yes" : "No"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Button fullWidth color="secondary" href="/changepassword">
            Change Password
          </Button>
        </>
      )}
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
  data: PropTypes.object.isRequired,
};

export default ProfileInfoCard;

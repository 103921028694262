/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import Box from "@mui/material/Box";

// Material Kit 2 React examples
import DefaultInfoCard from "../components/InfoCard";
import CenteredBlogCard from "../components/BlogCard";
import Cross from "../../../images/cross.svg?react";
import Coach from "../../../images/coach.svg?react";
import Luminous from "../../../images/luminous.svg?react";
import Teamwork from "../../../images/teamwork.svg?react";
import Programming from "../../../images/programming-background-with-person-working-with-codes-computer.jpg";
import { SvgIcon } from "@mui/material";
import MuiLink from "@mui/material/Link";

import Typography from "../components/Typography";

const cross = (
  <SvgIcon
    component={Cross}
    inheritViewBox
    sx={{ width: "40px", height: "40px" }}
  />
);
const coach = (
  <SvgIcon
    component={Coach}
    inheritViewBox
    sx={{ width: "40px", height: "40px" }}
  />
);
const luminous = (
  <SvgIcon
    component={Luminous}
    inheritViewBox
    sx={{ width: "40px", height: "40px" }}
  />
);
const teamwork = (
  <SvgIcon
    component={Teamwork}
    inheritViewBox
    sx={{ width: "40px", height: "40px" }}
  />
);

const imageSubtitle = (
  <Typography display="inline" variant="subtitle2">
    Image by{" "}
    <MuiLink href="https://www.freepik.com/free-photo/programming-background-with-person-working-with-codes-computer_38669459.htm#query=web%20developer&position=2&from_view=keyword&track=ais&uuid=6dc0588a-943d-4176-8dae-88ed1dced2de">
      Freepik
    </MuiLink>
  </Typography>
);
function Information() {
  return (
    <Box component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <Box mb={5}>
                  <DefaultInfoCard
                    icon={cross}
                    title="Christ minded"
                    description="Coach, play and cheer in a Christ-like manner"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={5}>
                  <DefaultInfoCard
                    icon={teamwork}
                    title="Fair"
                    description="All players and cheerleaders get equal playtime"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon={luminous}
                    title="Uplifting"
                    description="We aim to build confidence, not tear it down"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon={coach}
                    title="Positive Role Modeling"
                    description="Coaches that offer positive influence to your child"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={Programming}
              imageSubtitle={imageSubtitle}
              title="Do you code?"
              description="If you currently code, or are interested in learning to code, and would like to help me out with this website, please let me know!"
              action={{
                type: "internal",
                route: "/coding",
                color: "info",
                label: "interested!",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Information;

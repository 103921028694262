import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EventList from "./EventList";
import EventCreate from "./EventCreate";
import EventEdit from "./EventEdit";

const resource = {
  list: EventList,
  create: EventCreate,
  edit: EventEdit,
  icon: CalendarMonthIcon,
  recordRepresentation: (record: any) => `${record.title}`,
};

export default resource;

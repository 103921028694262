import * as React from "react";
import {
  Create,
  DateTimeInput,
  SimpleForm,
  TextInput,
  NumberInput,
  FileInput,
  FileField,
  SelectInput,
  BooleanInput,
} from "react-admin";
import { Box, Typography } from "@mui/material";

export const validateForm = (
  values: Record<string, any>,
): Record<string, any> => {
  const errors = {} as any;
  if (!values.title) {
    errors.title = "ra.validation.required";
  }
  if (!values.vendor) {
    errors.vendor = "ra.validation.required";
  }
  if (!values.category) {
    errors.category = "ra.validation.required";
  }
  if (!values.description) {
    errors.description = "ra.validation.required";
  }
  return errors;
};

const ExpenseCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm
        sx={{ maxWidth: 500 }}
        // Here for the GQL provider
        defaultValues={{
          date: new Date(),
          title: "",
          description: "",
          vendor: "",
          category: "",
          actualAmount: 0,
          budgetedAmount: 0,
        }}
        validate={validateForm}
      >
        <Typography variant="h6" gutterBottom>
          Expenses
        </Typography>
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <DateTimeInput
              source="date"
              fullWidth
              helperText={false}
              // value={startDate}
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <BooleanInput source="reimbursed" />
          </Box>
        </Box>
        <TextInput type="text" source="title" isRequired fullWidth />
        <TextInput type="text" source="description" fullWidth />
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput type="text" source="vendor" fullWidth />
          </Box>

          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <SelectInput
              source="category"
              fullWidth
              choices={[
                { id: "uniforms", name: "Uniforms" },
                { id: "equipment", name: "Equipment" },
                { id: "supplies", name: "Supplies" },
                { id: "prizes", name: "Prizes" },
              ]}
            />
          </Box>
        </Box>
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <NumberInput source="actualAmount" fullWidth helperText={false} />
          </Box>

          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <NumberInput source="budgetedAmount" fullWidth helperText={false} />
          </Box>
        </Box>
        <FileInput type="text" source="receipt" fullWidth>
          <FileField source="src" title="receipt" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

const SectionTitle = ({ label }: { label: string }) => {
  return (
    <Typography variant="h6" gutterBottom>
      {label}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

export default ExpenseCreate;

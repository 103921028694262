import axios from "axios";
import Backend from "../utilities/Backend";

const API_URL = `${Backend}/`;
const EventService = new (class EventService {
  getEvents() {
    return axios.get(API_URL + "user/events");
  }
})();

export default EventService;

import { useState, useEffect, Fragment } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AuthService from "../services/auth.service";
import AdminService from "../services/admin.service";
// import Table from "components/table";
import useLongPress from "../utilities/longPress";
import {
  Button,
  Box,
  Select,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Grid,
  tableCellClasses,
} from "@mui/material";
import EventBus from "../utilities/EventBus";

interface Player {
  rank: number;
  firstName: string;
  lastName: string;
  id: number;
  present: boolean;
  playerId: number;
}

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(1)": {
    backgroundColor: "#7AB4E4",
  },
  "&:nth-of-type(2)": {
    backgroundColor: "#8bc34a",
  },
  "&:nth-of-type(3)": {
    backgroundColor: "#ff9800",
  },
  "&:nth-of-type(5)": {
    backgroundColor: "#ffeb3b",
  },
}));

const AdminRotation = () => {
  const [redirect, setRedirect] = useState("");
  const [gameNumber, setGameNumber] = useState(1);
  const [courtATeamSize, setCourtATeamSize] = useState(5);
  const [courtBTeamSize, setCourtBTeamSize] = useState(5);
  const [courtA1, setCourtA1] = useState(0);
  const [courtA2, setCourtA2] = useState(0);
  const [courtB1, setCourtB1] = useState(0);
  const [courtB2, setCourtB2] = useState(0);
  const [initialLineup, setInitialLineup] = useState(true);
  const [period, setPeriod] = useState(1);
  const [data, setData] = useState([{ team: "default", players: [] }]);
  let [players, setPlayers] = useState([
    [
      {
        id: 0,
        firstName: "default",
        lastName: "default",
        rank: 0,
        present: true,
        playerId: 0,
      },
    ],
  ]);
  const courtA1Index = 0;
  const courtA2Index = 1;
  const courtB1Index = 2;
  const courtB2Index = 3;
  const [content, setContent] = useState("");
  let [error, setError] = useState(false);
  const location = useLocation().pathname;

  function compare(a: Player, b: Player) {
    if (a.rank < b.rank) {
      return -1;
    }

    if (a.rank > b.rank) {
      return 1;
    }
    return 0;
  }

  const sortPlayers = () => {
    var sorted = [];
    for (let team = 0; team < 4; team++) {
      const courtAStartingPos =
        (gameNumber - 1 + courtATeamSize * (period - 1)) % players[team].length;
      const courtBStartingPos =
        (gameNumber - 1 + courtBTeamSize * (period - 1)) % players[team].length;
      let teamSize = 0;
      var rosterCounter = 0;
      if (team < 2) {
        teamSize = courtATeamSize;
        rosterCounter = courtAStartingPos;
      } else {
        teamSize = courtBTeamSize;
        rosterCounter = courtBStartingPos;
      }
      var activePlayerList: any[] = [];
      var playerIndexList: any[] = [];
      var rowCounter = 0;

      let presentPlayers = 0;
      for (let i = 0; i < players[team].length; i++) {
        if (players[team][i].present) {
          presentPlayers++;
        }
      }
      if (presentPlayers < teamSize) {
        error = true;
      } else {
        rowCounter = 0;
        while (rowCounter < teamSize) {
          if (players[team][rosterCounter].present === true) {
            activePlayerList.push(players[team][rosterCounter].id);
            playerIndexList.push(rosterCounter);
            rowCounter++;
          }
          rosterCounter++;
          if (rosterCounter >= players[team].length) {
            rosterCounter = 0;
          }
        }

        for (let k = 0; k < teamSize; k++) {
          for (let j = 0; j < 4; j++) {
            if (playerIndexList[j] > playerIndexList[j + 1]) {
              var playerHolder = activePlayerList[j];
              var indexHolder = playerIndexList[j];
              playerIndexList[j] = playerIndexList[j + 1];
              activePlayerList[j] = activePlayerList[j + 1];
              playerIndexList[j + 1] = indexHolder;
              activePlayerList[j + 1] = playerHolder;
            }
          }
        }
      }
      sorted.push(activePlayerList);
    }
    return sorted;
  };

  function createData(
    color: string,
    team1: string,
    team2: string,
    team3: string,
    team4: string,
  ) {
    return { color, team1, team2, team3, team4 };
  }

  const LineUp = (teamIndex: number, playerIndex: number) => {
    const playerList = data[teamIndex].players.map((player: any) => ({
      rank: player.rank,
      firstName: player.firstName,
      lastName: player.lastName,
      playerId: player._id,
      present: true,
      id: player.rank,
    }));
    //@ts-ignore
    const sortedPlayers: [] = playerList.sort(compare);
    const temp = players;
    temp[playerIndex] = sortedPlayers;
    setPlayers(temp);
  };

  useEffect(() => {
    if (location === "/adminrotation") {
      const currentUser = AuthService.getAuthenticated();
      const isAdmin = AuthService.isAdmin();

      if (!currentUser) {
        setRedirect("/login");
      } else if (!isAdmin) {
        setRedirect("/");
      }
      if (data[0].team === "default") {
        AdminService.getTeams().then(
          (response) => {
            setData(response.data);
          },
          (error) => {
            console.error("erred retrieving players");
            setContent(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString(),
            );

            if (error.response && error.response.status === 401) {
              EventBus.dispatch("logout");
              setRedirect("/");
            }
          },
        );
      }
    }
  }, [players]);

  const onLongPress = async (event: any) => {
    setError(false);
    const itemId = event.target.id;
    const index = event.target.name as number;
    const tempPlayers = [...players];
    const teamPlayers = tempPlayers[index].slice();
    teamPlayers[itemId - 1].present = !players[index][itemId - 1].present;
    // players[index] = teamPlayers
    // const presentPlayers = [...players]
    setPlayers(tempPlayers);
  };

  // Short clicks are not needed
  const onClick = async (event: any) => {
    setError(false);
    const itemId = event.target.id;
    const index = event.target.name as number;
    const tempPlayers = [...players];
    const teamPlayers = players[index].slice();
    teamPlayers[itemId - 1].present = !players[index][itemId - 1].present;
    // const presentPlayers = players
    // presentPlayers[index] = tempPlayers
    setPlayers(tempPlayers);
  };

  const onGameChange = (event: any) => {
    setError(false);
    setGameNumber(Number(event.target.value));
  };

  const onPlayerChange = (event: any, court: string) => {
    setError(false);
    if (court === "A") setCourtATeamSize(Number(event.target.value));
    if (court === "B") setCourtBTeamSize(Number(event.target.value));
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };
  const pressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  if (initialLineup && data[0].team !== "default") {
    LineUp(courtA1, 0);
    LineUp(courtA2, 1);
    LineUp(courtB1, 2);
    LineUp(courtB2, 3);
    setInitialLineup(false);
  } else if (data[0].team !== "default") {
    const sorted = sortPlayers();
    let rowBuilder = [];
    const colors = ["Blue", "Green", "Orange", "White", "Yellow"];
    for (let i = 0; i < 5; i++) {
      // if (i > players[0].length || i > players[1].length)
      rowBuilder.push(
        createData(
          colors[i],
          courtATeamSize > i
            ? players[0][sorted[0][i] - 1].firstName +
                " " +
                Array.from(players[0][sorted[0][i] - 1].lastName)[0]
            : "",
          courtATeamSize > i
            ? players[1][sorted[1][i] - 1].firstName +
                " " +
                Array.from(players[1][sorted[1][i] - 1].lastName)[0]
            : "",
          courtBTeamSize > i
            ? players[2][sorted[2][i] - 1].firstName +
                " " +
                Array.from(players[2][sorted[2][i] - 1].lastName)[0]
            : "",
          courtBTeamSize > i
            ? players[3][sorted[3][i] - 1].firstName +
                " " +
                Array.from(players[3][sorted[3][i] - 1].lastName)[0]
            : "",
        ),
      );
    }
    if (redirect !== "") {
      return <Navigate to={redirect} />;
    }
    return (
      <Fragment>
        <Grid container spacing={1} marginBottom={2}>
          <Grid item xs={3} sm={3} md={1.5}>
            <Box sx={{ paddingLeft: "9px", paddingTop: "8px" }}>
              <FormControl fullWidth>
                <InputLabel id="week-selector-label">Week</InputLabel>
                <Select
                  labelId="week-selector-label"
                  id="week-selector"
                  defaultValue={1}
                  label="Week"
                  onChange={onGameChange}
                >
                  <MenuItem value={1}>Week 1</MenuItem>
                  <MenuItem value={2}>Week 2</MenuItem>
                  <MenuItem value={3}>Week 3</MenuItem>
                  <MenuItem value={4}>Week 4</MenuItem>
                  <MenuItem value={5}>Week 5</MenuItem>
                  <MenuItem value={6}>Week 6</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={3} sm={3} md={1.5}>
            <Box sx={{ paddingLeft: "9px", paddingTop: "8px" }}>
              <FormControl fullWidth>
                <InputLabel id="court-a-team-size-selector-label">
                  Court A Team Size
                </InputLabel>
                <Select
                  labelId="court-a-team-size-selector-label"
                  id="court-a-team-size-selector"
                  defaultValue={5}
                  label="Court A Team Size"
                  onChange={(e) => onPlayerChange(e, "A")}
                >
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={3} sm={3} md={1.5}>
            <Box sx={{ paddingLeft: "9px", paddingTop: "8px" }}>
              <FormControl fullWidth>
                <InputLabel id="court-b-team-size-selector-label">
                  Court B Team Size
                </InputLabel>
                <Select
                  labelId="court-b-team-size-selector-label"
                  id="court-b-team-size-selector"
                  defaultValue={5}
                  label="Court B Team Size"
                  onChange={(e) => onPlayerChange(e, "B")}
                >
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={3} sm={3} md={1}>
            <Box sx={{ paddingLeft: "9px", paddingTop: "8px" }}>
              <FormControl fullWidth>
                <InputLabel id="period-selector-label">Period</InputLabel>
                <Select
                  labelId="period-selector-label"
                  id="period-selector"
                  defaultValue={1}
                  label="Period"
                  onChange={(e) => setPeriod(e.target.value as number)}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={3} sm={4} md={1.5}>
            <Box sx={{ paddingLeft: "9px", paddingTop: "8px" }}>
              <FormControl fullWidth>
                <InputLabel id="courtA1-selector-label">
                  Court A Team 1
                </InputLabel>
                <Select
                  labelId="courtA1-selector-label"
                  id="courtA1-selector"
                  defaultValue={0}
                  label="Court A Team 1"
                  onChange={(e) => {
                    setCourtA1(e.target.value as number);
                    LineUp(e.target.value as number, courtA1Index);
                  }}
                >
                  {data.map(({ team }, index) => (
                    <MenuItem key={team} value={index}>
                      {team}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={3} sm={4} md={1.5}>
            <Box sx={{ paddingLeft: "9px", paddingTop: "8px" }}>
              <FormControl fullWidth>
                <InputLabel id="courtA2-selector-label">
                  Court A Team 2
                </InputLabel>
                <Select
                  labelId="courtA2-selector-label"
                  id="courtA2-selector"
                  defaultValue={0}
                  label="Court A Team 2"
                  onChange={(e) => {
                    setCourtA2(e.target.value as number);
                    LineUp(e.target.value as number, courtA2Index);
                  }}
                >
                  {data.map(({ team }, index) => (
                    <MenuItem key={team} value={index}>
                      {team}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={3} sm={4} md={1.5}>
            <Box sx={{ paddingLeft: "9px", paddingTop: "8px" }}>
              <FormControl fullWidth>
                <InputLabel id="courtB1-selector-label">
                  Court B Team 1
                </InputLabel>
                <Select
                  labelId="courtB1-selector-label"
                  id="courtB1-selector"
                  defaultValue={0}
                  label="Court B Team 1"
                  onChange={(e) => {
                    setCourtB1(e.target.value as number);
                    LineUp(e.target.value as number, courtB1Index);
                  }}
                >
                  {data.map(({ team }, index) => (
                    <MenuItem key={team} value={index}>
                      {team}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={3} sm={4} md={1.5}>
            <Box sx={{ paddingLeft: "9px", paddingTop: "8px" }}>
              <FormControl fullWidth>
                <InputLabel id="courtB2-selector-label">
                  Court B Team 2
                </InputLabel>
                <Select
                  labelId="courtB2-selector-label"
                  id="courtB2-selector"
                  defaultValue={0}
                  label="Court B Team 2"
                  onChange={(e) => {
                    setCourtB2(e.target.value as number);
                    LineUp(e.target.value as number, courtB2Index);
                  }}
                >
                  {data.map(({ team }, index) => (
                    <MenuItem key={team} value={index}>
                      {team}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        {data[0].team !== "default" && (
          <>
            {error ? (
              <Typography
                color="inherit"
                align="center"
                variant="h6"
                sx={{ zIndex: 2, mb: 10 }}
              >
                Number of available players is less than selected team size.
                Lower the number of players
              </Typography>
            ) : (
              <Box marginBottom="20px">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <Typography variant="h3">
                            <Box component="span" fontWeight="fontWeightMedium">
                              {" "}
                              {data[courtA1].team}
                            </Box>
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h3">
                            <Box component="span" fontWeight="fontWeightMedium">
                              {" "}
                              {data[courtA2].team}
                            </Box>
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h3">
                            <Box component="span" fontWeight="fontWeightMedium">
                              {" "}
                              {data[courtB1].team}
                            </Box>
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h3">
                            <Box component="span" fontWeight="fontWeightMedium">
                              {" "}
                              {data[courtB2].team}
                            </Box>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowBuilder.map((row) => (
                        <StyledTableRow
                          key={row.color}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {/* <TableCell component="th" scope="row">
                                                    {row.color}
                                                </TableCell> */}
                          <TableCell align="center">
                            <Typography variant="h3">{row.team1}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="h3">{row.team2}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="h3">{row.team3}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="h3">{row.team4}</Typography>
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
            <Grid container>
              <Grid item md={3}>
                {players[courtA1Index].map((player) => (
                  <Box
                    {...pressEvent}
                    name="0"
                    key={player.id.toString()}
                    id={player.id.toString()}
                    bgcolor={player.present ? "darkseagreen" : "lightpink"}
                    sx={{
                      mb: 2,
                      height: 40,
                      width: 1,
                      textAlign: "center",
                      typography: "h6",
                    }}
                    component="button"
                  >
                    {player.firstName + " " + player.lastName}
                  </Box>
                ))}
              </Grid>
              <Grid item md={3} paddingX={1}>
                {players[courtA2Index].map((player) => (
                  <Box
                    {...pressEvent}
                    name="1"
                    key={player.id.toString()}
                    id={player.id.toString()}
                    bgcolor={player.present ? "darkseagreen" : "lightpink"}
                    sx={{
                      mb: 2,
                      height: 40,
                      width: 1,
                      textAlign: "center",
                      typography: "h6",
                    }}
                    component="button"
                  >
                    {player.firstName + " " + player.lastName}
                  </Box>
                ))}
              </Grid>
              <Grid item md={3} paddingRight={1}>
                {players[courtB1Index].map((player) => (
                  <Box
                    {...pressEvent}
                    name="2"
                    key={player.id.toString()}
                    id={player.id.toString()}
                    bgcolor={player.present ? "darkseagreen" : "lightpink"}
                    sx={{
                      mb: 2,
                      height: 40,
                      width: 1,
                      textAlign: "center",
                      typography: "h6",
                    }}
                    component="button"
                  >
                    {player.firstName + " " + player.lastName}
                  </Box>
                ))}
              </Grid>
              <Grid item md={3}>
                {players[courtB2Index].map((player) => (
                  <Box
                    {...pressEvent}
                    name="3"
                    key={player.id.toString()}
                    id={player.id.toString()}
                    bgcolor={player.present ? "darkseagreen" : "lightpink"}
                    sx={{
                      mb: 2,
                      height: 40,
                      width: 1,
                      textAlign: "center",
                      typography: "h6",
                    }}
                    component="button"
                  >
                    {player.firstName + " " + player.lastName}
                  </Box>
                ))}
              </Grid>
            </Grid>
          </>
        )}
      </Fragment>
    );
  }
};

export default AdminRotation;

import axios from "axios";
import authHeader from "./auth-header";
import Backend from "../utilities/Backend";

const API_URL = `${Backend}/notifications/`;
const NotificationService = new (class NotificationService {
  notificationCount(id: string) {
    return axios.get(API_URL + "count/" + id, { headers: authHeader() });
  }

  getNotifications(id: string) {
    return axios.get(API_URL + "/" + id, { headers: authHeader() });
  }

  unsubscribe(id: string) {
    return axios.delete(API_URL + id);
  }

  isSubscribed(id: string) {
    return axios.get(API_URL + id).then((response) => {
      return response.data.isSubscribed;
    });
  }
})();

export default NotificationService;

import { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import Typography from "./modules/components/Typography";
import AppFooter from "./modules/views/AppFooter";
import AppForm from "./modules/views/AppForm";
import FormButton from "./modules/form/FormButton";
import AuthService from "../services/auth.service";
import EventBus from "../utilities/EventBus";

const passwordSpecialCharacters = /^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/;
const validationSchema = () => {
  return Yup.object({
    oldPassword: Yup.string().required("Password is required!"),
    newPassword: Yup.string()
      .min(8, "Password should be of minimum 8 characters length")
      .matches(
        passwordSpecialCharacters,
        "Needs to include a special character",
      )
      .required("Password is required!"),
    newPasswordConfirmation: Yup.string()
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.newPassword === value;
      })
      .required("This field is required!"),
  });
};

const ChangePassword = () => {
  const [redirect, setRedirect] = useState("");
  const [sent, setSent] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const location = useLocation().pathname;

  useEffect(() => {
    if (location === "/changepassword") {
      const currentUser = AuthService.getAuthenticated();
      if (!currentUser) {
        setRedirect("/login");
      }
      if (userEmail === "") {
        setUserEmail(currentUser.email);
      }
    }
  }, [userEmail]);

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      newPasswordConfirmation: "",
      oldPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setFieldError }) => {
      setSent(true);
      await AuthService.changePassword(
        userEmail,
        values.newPassword,
        values.oldPassword,
      ).then(
        //@ts-ignore
        (response) => {
          setRedirect("/profile");
        },
        //@ts-ignore
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
            setRedirect("/");
          }
        },
      );
    },
  });

  if (redirect) {
    return <Navigate to={redirect} />;
  }
  return (
    <>
      <AppForm>
        <>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Change Password
          </Typography>
        </>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Box sx={{ mt: 6 }}>
            <TextField
              disabled={sent}
              fullWidth
              label="Old Password"
              name="oldPassword"
              id="oldPassword"
              type="password"
              value={formik.values.oldPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
              }
              helperText={
                formik.touched.oldPassword && formik.errors.oldPassword
              }
              required
            />
            <TextField
              disabled={sent}
              fullWidth
              label="New Password"
              name="newPassword"
              required
              type="password"
              id="newPassword"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.newPassword && Boolean(formik.errors.newPassword)
              }
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
            />

            <TextField
              disabled={sent}
              fullWidth
              label="Confirm Password"
              margin="normal"
              name="newPasswordConfirmation"
              type="password"
              required
              id="newPasswordConfirmation"
              value={formik.values.newPasswordConfirmation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.newPasswordConfirmation &&
                Boolean(formik.errors.newPasswordConfirmation)
              }
              helperText={
                formik.touched.newPasswordConfirmation &&
                formik.errors.newPasswordConfirmation
              }
            />
            <FormButton
              sx={{ mt: 3, mb: 2 }}
              disabled={sent}
              color="secondary"
              fullWidth
            >
              {sent ? "In progress…" : "Change Password"}
            </FormButton>
            <FormButton
              sx={{ mt: 3, mb: 2 }}
              disabled={sent}
              color="secondary"
              fullWidth
              href="/profile"
            >
              Cancel
            </FormButton>
          </Box>
        </form>
      </AppForm>
      <AppFooter />
    </>
  );
};

export default ChangePassword;

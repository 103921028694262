import * as React from "react";
import { NumberInput, Edit, SelectInput, SimpleForm } from "react-admin";
import { Grid, Box, Typography } from "@mui/material";

import { validateForm } from "./GroupCreate";

const GroupEdit = () => {
  return (
    <Edit>
      <SimpleForm
        sx={{ maxWidth: 500 }}
        // Here for the GQL provider
        defaultValues={{
          sport: "B",
        }}
        validate={validateForm}
      >
        <Typography variant="h6" gutterBottom>
          Groups
        </Typography>
        <SelectInput
          source="sport"
          isRequired
          choices={[
            { id: "B", name: "Basketball" },
            { id: "C", name: "Cheerleading" },
          ]}
        />
        <SelectInput
          source="gender"
          isRequired
          choices={[
            { id: "M", name: "Boys" },
            { id: "F", name: "Girls" },
            { id: "C", name: "Co-ed" },
          ]}
        />
        <SelectInput
          source="gradeMin"
          isRequired
          choices={[
            { id: "0", name: "K" },
            { id: "1", name: "1" },
            { id: "2", name: "2" },
            { id: "3", name: "3" },
            { id: "4", name: "4" },
            { id: "5", name: "5" },
            { id: "6", name: "6" },
          ]}
        />
        <SelectInput
          source="gradeMax"
          isRequired
          choices={[
            { id: "0", name: "K" },
            { id: "1", name: "1" },
            { id: "2", name: "2" },
            { id: "3", name: "3" },
            { id: "4", name: "4" },
            { id: "5", name: "5" },
            { id: "6", name: "6" },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default GroupEdit;

/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

import { useFormik, Formik } from "formik";
import * as Yup from "yup";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import SvgIcon from "@mui/material/SvgIcon";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import {
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  FormGroup,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";

import Typography from "@mui/material/Typography";
import VolunteerService from "../../../services/volunteer.service";
//@ts-ignore
function CoachInfoCard({ title, description, action, shadow, data }) {
  const [editMode, setEditMode] = useState(false);
  const [sent, setSent] = useState(false);

  const validationSchema = () => {
    return Yup.object({
      ageGroups: Yup.string().required("Age group cannot be blank"),
      poloSize: Yup.string().required("Size number cannot be blank"),
      coachMeeting: Yup.string().required("Meeting is required!"),
    });
  };

  const handleSubmit = async (formValue: {
    ageGroups: string[];
    teamName: string;
    preferredPractice: string;
    poloSize: string;
    coachMeeting: string;
  }) => {
    setSent(true);

    await VolunteerService.coachInfoUpdate(
      formValue.ageGroups,
      formValue.preferredPractice,
      formValue.poloSize,
      formValue.coachMeeting,
    ).then(
      () => {
        setEditMode(false);
        setSent(false);
        data.ageGroups = formValue.ageGroups;
        data.preferredPractice = formValue.preferredPractice;
        data.poloSize = formValue.poloSize;
        data.coachMeeting = formValue.coachMeeting;
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setSent(false);
      },
    );
  };

  const editToggle = () => {
    setEditMode(!editMode);
  };
  return (
    <Card sx={{ height: "100%", boxShadow: 1, width: "100%" }}>
      {editMode ? (
        <Formik
          initialValues={data}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <form noValidate>
              <Box sx={{ mt: 2, mx: 1 }}>
                <Typography
                  variant="h6"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  Edit Preferences
                </Typography>
                <FormControl fullWidth margin="normal" required>
                  <InputLabel id="polo-size-select-label">Polo Size</InputLabel>
                  <Select
                    labelId="polo-size-select-label"
                    id="polo-size"
                    required
                    // value={state}
                    name="polo-size"
                    label="Polo Size"
                    onChange={props.handleChange}
                  >
                    <MenuItem value="Small">Small</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="Large">Large</MenuItem>
                    <MenuItem value="XL">XL</MenuItem>
                    <MenuItem value="XXL">XXL</MenuItem>
                    <MenuItem value="3XL">3XL</MenuItem>
                    <MenuItem value="4XL">4XL</MenuItem>
                    <MenuItem value="5XL">5XL</MenuItem>
                  </Select>
                </FormControl>
                {/* <FormControl fullWidth>
                  <FormLabel id="polo-size">Polo Size</FormLabel>
                  <RadioGroup
                    aria-labelledby="polo-size"
                    name="polo-size"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4} xl={4}>
                        <FormControlLabel value="small" control={<Radio onChange={props.handleChange} name="poloSize" checked={props.values.poloSize === "small"} />} label="Small" />
                        <FormControlLabel value="medium" control={<Radio onChange={props.handleChange} name="poloSize" checked={props.values.poloSize === "medium"} />} label="Medium" />
                        <FormControlLabel value="large" control={<Radio onChange={props.handleChange} name="poloSize" checked={props.values.poloSize === "large"}/>} label="Large" />
                      </Grid>
                      <Grid item xs={12} md={4} xl={4}>
                        <FormControlLabel value="XL" control={<Radio onChange={props.handleChange} name="poloSize" checked={props.values.poloSize === "XL"}/>} label="XL" />
                        <FormControlLabel value="XXL" control={<Radio onChange={props.handleChange} name="poloSize" checked={props.values.poloSize === "XXL"}/>} label="XXL" />
                        <FormControlLabel value="3XL" control={<Radio onChange={props.handleChange} name="poloSize" checked={props.values.poloSize === "3XL"}/>} label="3XL" />
                      </Grid>
                      <Grid item xs={12} md={4} xl={4}>
                        <FormControlLabel value="4XL" control={<Radio onChange={props.handleChange} name="poloSize" checked={props.values.poloSize === "4XL"}/>} label="4XL" />
                        <FormControlLabel value="5XL" control={<Radio onChange={props.handleChange} name="poloSize" checked={props.values.poloSize === "5XL"}/>} label="5XL" />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl> */}
                {/* <TextField
                  variant='outlined'
                  autoComplete="teamName"
                  disabled={sent}
                  fullWidth
                  label="Team Name"
                  margin="normal"
                  name="teamName"
                  id="teamName"
                  value={props.values.teamName}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={props.touched.teamName && Boolean(props.errors.teamName)}
                  helperText={props.touched.teamName && props.errors.teamName}
                /> */}
                <TextField
                  variant="outlined"
                  autoComplete="preferredPractice "
                  disabled={sent}
                  fullWidth
                  label="Practice time/night preferences"
                  margin="normal"
                  name="preferredPractice"
                  id="preferredPractice"
                  value={props.values.preferredPractice}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={
                    props.touched.preferredPractice &&
                    Boolean(props.errors.preferredPractice)
                  }
                  helperText={
                    props.touched.preferredPractice &&
                    props.errors.preferredPractice
                  }
                />
                <FormControl fullWidth>
                  <FormLabel id="ageGroups">Age Group(s)</FormLabel>
                  <FormGroup>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4} xl={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={props.handleChange}
                              name="ageGroups"
                              checked={props.values.ageGroups.includes(
                                "Boys12",
                              )}
                            />
                          }
                          label="Boys 1-2"
                          value="Boys12"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={props.handleChange}
                              name="ageGroups"
                              checked={props.values.ageGroups.includes(
                                "Boys34",
                              )}
                            />
                          }
                          label="Boys 3-4"
                          value="Boys34"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={props.handleChange}
                              name="ageGroups"
                              checked={props.values.ageGroups.includes(
                                "Boys56",
                              )}
                            />
                          }
                          label="Boys 5-6"
                          value="Boys56"
                        />
                      </Grid>
                      <Grid item xs={12} md={4} xl={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={props.handleChange}
                              name="ageGroups"
                              checked={props.values.ageGroups.includes(
                                "Girls13",
                              )}
                            />
                          }
                          label="Girls 1-3"
                          value="Girls13"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={props.handleChange}
                              name="ageGroups"
                              checked={props.values.ageGroups.includes(
                                "Girls46",
                              )}
                            />
                          }
                          label="Girls 4-6"
                          value="Girls46"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={props.handleChange}
                              name="ageGroups"
                              checked={props.values.ageGroups.includes(
                                "Cheerleading",
                              )}
                            />
                          }
                          label="Cheerleading"
                          value="Cheerleading"
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </FormControl>
                <FormControl fullWidth>
                  <FormLabel id="coachMeeting">
                    Pre-season Coach's Meeting
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="coaches meeting"
                    name="coachMeeting"
                  >
                    <FormControlLabel
                      value="Date1"
                      control={<Radio />}
                      label="Date1"
                    />
                    <FormControlLabel
                      value="Date2"
                      control={<Radio />}
                      label="Date2"
                    />
                    <FormControlLabel
                      value="none"
                      control={<Radio />}
                      label="Can't make either"
                    />
                  </RadioGroup>
                </FormControl>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      sx={{ mb: 2 }}
                      fullWidth
                      disabled={sent}
                      color="secondary"
                      variant="contained"
                      type="submit"
                    >
                      {sent ? "In progress…" : "Save"}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      sx={{ mb: 2 }}
                      fullWidth
                      disabled={sent}
                      color="secondary"
                      variant="contained"
                      onClick={editToggle}
                    >
                      {sent ? "In progress…" : "Cancel"}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              {/* <pre>{JSON.stringify(props.values, null, 2)}</pre> */}
            </form>
          )}
        </Formik>
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pt={2}
            px={2}
          >
            <Typography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {title}
            </Typography>
            <Typography
              component={Link}
              to={action.route}
              variant="body2"
              color="secondary"
            >
              <Tooltip title={action.tooltip} placement="top">
                <SvgIcon component={EditIcon} onClick={editToggle} />
              </Tooltip>
            </Typography>
          </Box>
          <Box p={2}>
            <Box sx={{ opacity: 0.3 }}>
              <Divider />
            </Box>
            <Box>
              <Box key="ageGroups" display="flex" py={1} pr={2}>
                <Typography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Age Groups: &nbsp;
                </Typography>
                <Typography variant="button" fontWeight="regular" color="text">
                  &nbsp;{data.ageGroups.map((group: string) => `${group}, `)}
                </Typography>
              </Box>
              {/* <Box key="teamName" display="flex" py={1} pr={2}>
                <Typography variant="button" fontWeight="bold" textTransform="capitalize">
                  Team Name: &nbsp;
                </Typography>
                <Typography variant="button" fontWeight="regular" color="text">
                  &nbsp;{data.teamName}
                </Typography>
              </Box> */}
              <Box key="practicePreferences" display="flex" py={1} pr={2}>
                <Typography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Practice Preferrences: &nbsp;
                </Typography>
                <Typography variant="button" fontWeight="regular" color="text">
                  &nbsp;
                  {data.preferredPractice ? data.preferredPractice : "None"}
                </Typography>
              </Box>
              <Box key="poloSize" display="flex" py={1} pr={2}>
                <Typography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Polo Size: &nbsp;
                </Typography>
                <Typography variant="button" fontWeight="regular" color="text">
                  &nbsp;{data.poloSize}
                </Typography>
              </Box>
              <Box key="coachMeeting" display="flex" py={1} pr={2}>
                <Typography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Coach Meeting: &nbsp;
                </Typography>
                <Typography variant="button" fontWeight="regular" color="text">
                  &nbsp;{data.coachMeeting}
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Card>
  );
}

// Setting default props for the CoachInfoCard
CoachInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the CoachInfoCard
CoachInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
  data: PropTypes.object.isRequired,
};

export default CoachInfoCard;
